'use strict';

export default function () {
  if (jQuery('.animate').length) {
    var elems = jQuery('.animate'),
      elemsArr = [],
      docViewTop = jQuery(window).scrollTop(),
      docViewBottom = docViewTop + jQuery(window).height(),
      elemTop = null;
    elems.each(function () {
      elemTop = jQuery(this).offset().top;
      if ((elemTop <= docViewBottom) && (elemTop >= docViewTop) && !jQuery(this).hasClass('animated')) {
        elemsArr.push(jQuery(this));
      }
    });
    for (var index = 0; index < elemsArr.length; index++) {
      (function (that, i) {
        var anim = (jQuery(elemsArr[i]).data('animation') != null) ? jQuery(elemsArr[i]).data('animation') : 'slideInUp';
        var delay = jQuery(elemsArr[i]).data('animation-delay');
        setTimeout(function () {
          jQuery(elemsArr[i]).addClass('animated ' + anim);
          jQuery(elemsArr[i]).removeClass('animate');
        }, (delay) ? delay : (100 * i));
      })(this, index);
    }
  }
}
