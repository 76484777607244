'use strict';

export default function (elem) {
  let ratings = (elem) ? elem : document.getElementsByClassName('star-ratings-static');
  for (let i = 0; i < ratings.length; i++) {
    let el = ratings[i],
      ratingUpper = 5,
      ratingValue = el.getAttribute('data-rating'),
      ratingList = document.createElement("ul");
    ratingList.className = 'rating list-unstyled';
    el.appendChild(ratingList);
    let starList = el.getElementsByClassName('rating');
    for (let i2 = 0; i2 < ratingUpper; i2++) {
      let filledStar = '<li class="filled" data-starnum="' + i2 + '"><div class="star-wrapper"><svg class="icon icon-xs icon-filled primary"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-star-filled"></use></svg></div></li>',
        emptyStar = '<li class="empty" data-starnum="' + i2 + '"><div class="star-wrapper"><svg class="icon icon-xs icon-empty"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-star"></use></svg></div></li>';
      let ratingRounded = Math.floor(parseFloat(ratingValue));
      ((ratingRounded) >= (i2)) ? starList[0].innerHTML += filledStar : starList[0].innerHTML += emptyStar;
      if ((ratingRounded) === i2) {
        let partialStar = el.getElementsByClassName('star-wrapper')[i2];
        partialStar.style.width = ((ratingValue - ratingRounded) * 100) + '%';
      }
    }
  }
}
