'use strict';

import ValidateForm from './form.js';

const DEFAULT_VALIDATE_CLASS = 'form-validate';

export default class Validate {
  static init(selector = DEFAULT_VALIDATE_CLASS) {
    const forms = document.querySelectorAll(`.${selector}`);

    Validate.addEventListeners(selector);

    return [...forms].map(form => new ValidateForm(form));
  }

  static addEventListeners() {
    document.addEventListener('validateFormElementBlur', function (event) {
      event.detail.formElement.validate();
    }, true);

    document.addEventListener('validateFormElementClick', function (event) {
      event.detail.formElement.validate();
    }, true);

    document.addEventListener('validateFormSubmit', function (event) {
      event.detail.form.validate(true);
    }, false);
  }
}
