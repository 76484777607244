'use strict';

export default class ValidationError extends Error {
  constructor(errorMessage, formField, ...params) {
    super(errorMessage, ...params);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ValidationError);
    }

    this.errorMessage = errorMessage;

    this.formField = formField;
  }
}
