'use strict';

import ValidateFormElement from './formElement.js';

export default class ValidateForm {
  constructor(form) {
    this.form = form;

    this.form.setAttribute('novalidate', true);

    const formElements = this.form.elements;

    this.formElements = [...formElements].map(formElement => new ValidateFormElement(formElement));

    this.form.addEventListener('submit', event => {
      event.preventDefault();

      this.form.dispatchEvent(
        new CustomEvent(
          "validateFormSubmit",
          {
            detail: {
              form: this,
              submitEvent: event,
            },
            bubbles: true,
            cancelable: true
          }
        )
      );
    }, false);
  }

  get isValid() {
    return this.formErrors.length === 0;
  }

  get formErrors() {
    return this.formElements.filter(formElement => formElement.isValid === false);
  }

  validate(submit = false) {
    this.formElements.forEach(formElement => {
      formElement.validate()
    });

    if (this.isValid === false) {
      this.formErrors[0].focus();
    } else if (submit === true) {
      this.form.submit();
    }
  }
}
