export default function () {

  let triggers = document.getElementsByClassName('select-trigger');
  for (let i = 0; i < triggers.length; i++) {
    let trigger = triggers[i].querySelector('input');
    trigger.addEventListener('click', function () {
      let container = document.getElementById(triggers[i].dataset.target.replace('#', ''));
      let checkboxes = container.getElementsByClassName('select-target');
      for (let i2 = 0; i2 < checkboxes.length; i2++) {
        let checkbox = checkboxes[i2].querySelector('input');
        (trigger.checked) ? checkbox.checked = true : checkbox.checked = false;
      }
    });
  }
}
