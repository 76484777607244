import ratingsInteractive from './ratings-interactive';
import ratingsStatic from './ratings-static';
import quantitySelector from './forms/quantity';
import bulkEdit from './bulk-edit';
import inlineEdit from './inline-edit';
import carousel from './carousel';
import modals from './modals';
import nav from './nav';
import dropdowns from './dropdowns';
import {initDropzone} from './dropzone';
import {initDatePicker} from './forms/datepicker';
import animate from './animate';

export default function () {

  // document.arrive(".star-ratings-interactive", function() {
  //   ratingsInteractive([this]);
  // });

  let loadComponent = {
    'star-ratings-interactive' : ratingsInteractive,
    'star-ratings-static' : ratingsStatic,
    'quantity-selector' : quantitySelector,
    'js-bulk-edit-form-wrapper' : bulkEdit,
    'inline-edit' : inlineEdit,
    'carousel' : carousel,
    'aside' : modals,
    'pagination' : nav,
    'nav-vertical' : nav,
    'select-styled' : dropdowns,
    'dropzone' : initDropzone,
    'datepicker' : initDatePicker,
    'animate' : animate
  };

  function getComponent(elemClasses) {
    let component = null;
    for (let i = 0; i < elemClasses.length; i++) {
      if (loadComponent[elemClasses[i]]) {
        component = loadComponent[elemClasses[i]];
      }
    }
    return component;
  }

  document.arrive(".dynamic", function () {
    this.classList.remove('dynamic');
    let component = this.classList;
    let initComponent = getComponent(component);
    initComponent([this]);
  });

}
