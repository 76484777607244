import hoverBoard from './hoverboard';

export default function (elem) {

  function setActive(listItems, activeIndex) {
    for (let i3 = 0; i3 < listItems.length; i3++) {
      let item = listItems[i3];
      (item.classList.contains('active')) ? item.classList.remove('active') : '';
    }
    listItems[activeIndex].classList.add('active');
  }

  function setInput(inputElem, nextIndex) {
    inputElem.value = nextIndex + 1;
  }

  function setControls(pageControls, index, cnt) {
    let ctrlNext,
      ctrlPrev,
      ctrlFirst,
      ctrlLast;
    for (let i3 = 0; i3 < pageControls.length; i3++) {
      let pageControl = pageControls[i3];
      (pageControl.classList.contains('control-prev')) ? ctrlPrev = pageControl : '';
      (pageControl.classList.contains('control-next')) ? ctrlNext = pageControl : '';
      (pageControl.classList.contains('control-last')) ? ctrlLast = pageControl : '';
      (pageControl.classList.contains('control-first')) ? ctrlFirst = pageControl : '';
    }
    (index === 0) ? (ctrlPrev.classList.add('disabled'), ctrlFirst.classList.add('disabled')) : (ctrlPrev.classList.remove('disabled'), ctrlFirst.classList.remove('disabled'));
    (index === (cnt - 1)) ? (ctrlNext.classList.add('disabled'), ctrlLast.classList.add('disabled')) : (ctrlNext.classList.remove('disabled'), ctrlLast.classList.remove('disabled'));
  }

  function getActiveIndex(listItems) {
    let activePage;
    for (let i3 = 0; i3 < listItems.length; i3++) {
      let item = listItems[i3];
      (item.classList.contains('active')) ? activePage = i3 : '';
    }
    return activePage;
  }

  function getNextIndex(pageControl, activeIndex, pageCount) {
    let nextIndex;
    (pageControl.classList.contains('control-prev') && activeIndex !== 0) ? nextIndex = (activeIndex - 1) : activeIndex;
    (pageControl.classList.contains('control-next') && activeIndex !== pageCount) ? nextIndex = (activeIndex + 1) : activeIndex;
    (pageControl.classList.contains('control-last') && activeIndex !== pageCount) ? nextIndex = (pageCount - 1) : activeIndex;
    (pageControl.classList.contains('control-first') && activeIndex !== pageCount) ? nextIndex = 0 : activeIndex;
    return nextIndex;
  }

  // pagination list
  let pageLists = (elem) ? elem : document.getElementsByClassName('pagination-list');
  for (let i = 0; i < pageLists.length; i++) {
    let pageList = pageLists[i],
      pageItems = pageList.getElementsByClassName('page-number'),
      pageItemCnt = pageItems.length,
      pageControls = pageList.getElementsByClassName('page-control');
    for (let i2 = 0; i2 < pageItems.length; i2++) {
      let pageIndex = i2,
        pageItem = pageItems[i2];
      pageItem.addEventListener('click', function () {
        setActive(pageItems, pageIndex);
        setControls(pageControls, i2, pageItemCnt);
      });
    }
    for (let i3 = 0; i3 < pageControls.length; i3++) {
      let pageControl = pageControls[i3];
      pageControl.addEventListener('click', function () {
        let activeIndex = getActiveIndex(pageItems),
          nextIndex = getNextIndex(pageControl, activeIndex, pageItemCnt);
        setActive(pageItems, nextIndex, pageItemCnt);
        setControls(pageControls, nextIndex, pageItemCnt);
      })
    }
  }

  // pagination manual
  let pageManuals = (elem) ? elem : document.getElementsByClassName('pagination-manual');
  for (let i = 0; i < pageManuals.length; i++) {
    let pageManual = pageManuals[i],
      pageInputContainer = pageManual.querySelector('.page-input'),
      pageInput = pageInputContainer.querySelector('.page-input input'),
      pageCount = pageManual.dataset.pages,
      pageControls = pageManual.getElementsByClassName('page-control'),
      currentPage = pageInput.value,
      pageGo = pageInputContainer.querySelector('.page-go'),
      pageCancel = pageInputContainer.querySelector('.page-cancel');
    function setCurrentPage(page) {
      currentPage = page;
    }
    pageInputContainer.addEventListener('input', function () {
      (pageInput.value) ? pageManual.classList.add('input-focused') : pageManual.classList.remove('input-focused');
    });
    document.addEventListener('click', function (e) {
      if (!e.target.closest('.page-input')) {
        pageManual.classList.remove('input-focused');
        pageInput.value = currentPage;
      }
    });
    pageGo.addEventListener('click', function () {
      currentPage = pageInput.value;
      pageManual.classList.remove('input-focused');
    });
    pageInput.onkeydown = function (event) {
      if (event.keyCode === 13) {
        currentPage = pageInput.value;
        pageManual.classList.remove('input-focused');
      }
    };
    pageCancel.addEventListener('click', function () {
      pageInput.value = currentPage;
      pageManual.classList.remove('input-focused');
    });
    for (let i2 = 0; i2 < pageControls.length; i2++) {
      let pageControl = pageControls[i2];
      pageControl.addEventListener('click', function () {
        let page = pageInput.value;
        let nextIndex = getNextIndex(pageControl, (page - 1), pageCount);
        setInput(pageInput, nextIndex);
        setCurrentPage(pageInput.value);
        setControls(pageControls, nextIndex, pageCount);
      });

    }
  }

  if (document.querySelector('.nav-vertical')) {

    //Main Navigation
    $('.nav-vertical .nav-link, .mobile-navbar .nav-link').click(function () {
      $(this).closest('.nav').find('.nav-link').removeClass('active');
      $(this).addClass('active');
    });
    $('[data-toggle="aside-nav"]').click(function () {
      let target = $(this).data("target");
      $(target).toggleClass('open');
    });
    $('.mobile-menu-toggle').click(function () {
      $('body').toggleClass('mobile-menu-open');
    });

    //Footer Navigation
    //create mobile footer nav

    let footer = document.querySelector('.footer-wrapper.full-footer');
    let windowWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
    if (footer && windowWidth <= 576) {
      let menu = footer.querySelector('.menu-links');
      if (menu) {
        let menuItems = menu.getElementsByClassName('category');
        for (let i = 0; i < menuItems.length; i++) {
          let menuItem = menuItems[i];
          let collapseTrigger = menuItem.querySelector('.category-title');
          let collapseTarget = menuItem.querySelector('.category-list');
          collapseTrigger.classList.add('collapse');
          collapseTrigger.dataset.toggle = 'collapse';
          collapseTrigger.dataset.target = '#target-' + i;
          collapseTrigger.setAttribute('aria-expanded', 'false');
          collapseTrigger.setAttribute('aria-controls', 'target-' + i);
          collapseTarget.classList.add('collapse', 'collapse-body');
          collapseTarget.setAttribute('id', 'target-' + i);
        }
      }
    }

    hoverBoard();

  }
}
