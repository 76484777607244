export default function (elem) {
  let inlineEdit = (elem) ? elem : document.getElementsByClassName('inline-edit');
  for (let i = 0; i < inlineEdit.length; i++) {
    let el = inlineEdit[i],
      controls = el.getElementsByClassName("form-control");
    for (let i2 = 0; i2 < controls.length; i2++) {
      let control = controls[i2];
      control.parentElement.addEventListener('click', function () {
        control.removeAttribute('disabled');
        control.focus();
      });
      control.addEventListener('focusout', function () {
        control.setAttribute('disabled', 'true');
      })
    }
  }
}
