'use strict';

/*global Chart */

export default function () {

  function rand(arraySize) {
    let randomNum = Math.floor(Math.random() * (arraySize - 1 + 1) + 1);
    return (randomNum - 1);
  }

  function getVariations(theme, howMany, type) {
    return type(theme, howMany)
  }

  function desc(theme, howMany) {
    let result = [],
      inc = Math.round(theme.length / howMany),
      idx = (theme.length);
    for (let i = ((howMany * inc) - 1); i > -1; i -= inc) {
      (idx < 1) ? idx = (theme.length - 1) : idx -= inc;
      result.push(theme[idx]);
    }
    return result;
  }

  function asc(theme, howMany) {
    let result = [],
      inc = Math.round(theme.length / howMany),
      idx = 0;
    for (let i = 0; i < ((howMany) * inc); i += inc) {
      idx = i - theme.length;
      result.push((i < theme.length) ? theme[i] : theme[idx]);
    }
    return result;
  }

  function random(theme, howMany) {
    let result = [],
      randColor,
      origTheme = theme.slice(0),
      themeArray = theme;
    for (let i = 0; i < howMany; i++) {
      randColor = rand(themeArray.length);
      result.push(themeArray[randColor]);
      (themeArray.length < 2) ? themeArray = origTheme : themeArray.splice(randColor, 1);
    }
    return result;
  }

  function getChartConfig(chart) {
    let options = {};
    let defs = {
      'theme' : {
        'blue' : ['#13579a', '#2a67a4', '#4278ae', '#5989b8', '#719ac2', '#89abcc', '#a0bbd6', '#b8cce0', '#cfddea', '#e7eef4'],
        'purple' : ['#9552D9', '#9f63dc', '#aa74e0', '#b485e4', '#bf97e8', '#caa8ec', '#d4b9ef', '#dfcbf3', '#e9dcf7', '#f4edfb'],
        'red' : ['#ee3955', '#ef4c66', '#f16076', '#f37488', '#f48899', '#f69caa', '#f8afbb', '#f9c3cc', '#fbd7dd', '#fdebee'],
        'green' : ['#2ecc7f', '#42d18b', '#57d698', '#6cdba5', '#81e0b2', '#96e5bf', '#abeacb', '#c0efd8', '#d5f4e5', '#eaf9f2']
      },
      'pattern' : {
        'random' : random,
        'asc' : asc,
        'desc' : desc
      }
    };
    options.color = defs.theme[chart.dataset.theme];
    options.pattern = defs.pattern[chart.dataset.pattern];
    return options;
  }

  let charts = document.getElementsByClassName("chart");
  for (let i = 0; i < charts.length; i++) {
    let ctx = charts[i],
      options = getChartConfig(ctx),
      config = JSON.parse(ctx.dataset.config),
      numDataItems = null,
      numVariations = null,
      datasets = config.data.datasets,
      variations = null;

    for (let i2 = 0; i2 < datasets.length; i2++) {
      numDataItems = config.data.datasets[i2].data.length;
      (datasets.length > 1) ? numVariations = datasets.length : numVariations = numDataItems;
      variations = getVariations(options.color, numVariations, options.pattern);

      if (datasets.length > 1) {
        config.data.datasets[i2].backgroundColor = variations[i2];
        config.data.datasets[i2].borderColor = variations[i2];
      }
      else {
        config.data.datasets[i2].backgroundColor = variations;
      }
    }
    Chart.defaults.global.defaultFontColor = '#262d3b';
    Chart.defaults.global.defaultFontFamily = "'Nunito-sans', 'sans-serif'";
    Chart.defaults.global.defaultFontSize = 14;
    Chart.defaults.global.defaultFontStyle = 'bold';
    void new Chart(ctx, config);
  }
}
