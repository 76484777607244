'use strict';

export default function (elem) {
  function closeAllSelect(elmnt) {
    // a function that will close all select boxes in the document, except the current select box:
    let x, y, i, arrNo = [];
    x = document.getElementsByClassName("select-items");
    y = document.getElementsByClassName("select-selected");
    for (i = 0; i < y.length; i++) {
      if (elmnt === y[i]) {
        arrNo.push(i)
      } else {
        y[i].classList.remove("select-arrow-active");
      }
    }
    for (i = 0; i < x.length; i++) {
      if (arrNo.indexOf(i)) {
        x[i].classList.add("select-hide");
        x[i].parentNode.classList.remove("open");
      }
    }
  }

  let onItemClick = function (event) {
    // when an item is clicked, update the original select box, and the selected item:
    let y, i, k, s, h;
    let element = event.target;
    s = element.parentNode.parentNode.parentNode.getElementsByTagName("select")[0];
    h = element.parentNode.previousSibling;
    for (i = 0; i < s.length; i++) {
      if (s.options[i].innerHTML === this.innerHTML) {
        s.selectedIndex = i;
        h.innerHTML = element.innerHTML;
        y = element.parentNode.getElementsByClassName("same-as-selected");
        for (k = 0; k < y.length; k++) {
          y[k].removeAttribute("class");
        }
        element.setAttribute("class", "same-as-selected");
        break;
      }
    }
    h.click();
  };

  let onDivClick = function (event) {

    // when the select box is clicked, close any other select boxes, and open/close the current select box:
    let element = event.target;
    event.stopPropagation();
    closeAllSelect(element);
    element.nextSibling.classList.toggle("select-hide");
    element.classList.toggle("select-arrow-active");
    element.parentNode.classList.toggle("open");
  };

  if ($('.select-styled') || elem) {
    let selectStyled, i, j, selElmnt, selectedItem, selectOptions, c, dropdownWrapper;

    selectStyled = (elem) ? elem : document.getElementsByClassName('select-styled');
    for (i = 0; i < selectStyled.length; i++) {
      selElmnt = selectStyled[i].getElementsByTagName("select")[0];

      // for each element, create a new div that will act as the selected item:
      selectedItem = document.createElement("div");
      selectedItem.setAttribute("class", "select-selected");
      selectedItem.innerHTML = selElmnt.options[selElmnt.selectedIndex].innerHTML;

      // for each element, create a new div that will contain the option list:
      selectOptions = document.createElement("div");
      selectOptions.setAttribute("class", "select-items select-hide");
      for (j = 0; j < selElmnt.length; j++) {

        // for each option in the original select element, create a new div that will act as an option item:
        c = document.createElement("div");
        c.innerHTML = selElmnt.options[j].innerHTML;
        c.addEventListener("click", onItemClick);
        selectOptions.appendChild(c);
      }
      selectStyled[i].appendChild(selectOptions);


      dropdownWrapper = document.createElement("div");
      dropdownWrapper.appendChild(selectedItem);
      dropdownWrapper.setAttribute("class", "dropdown-wrapper");
      dropdownWrapper.appendChild(selectOptions);
      selectStyled[i].appendChild(dropdownWrapper);
      selectedItem.addEventListener("click", onDivClick);
    }

    /*if the user clicks anywhere outside the select box,
     then close all select boxes:*/
    document.addEventListener("click", closeAllSelect);
  }
}
