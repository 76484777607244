'use strict';

import ValidationError from './error.js';
import {hasError, showError, removeError} from './helpers.js';

export default class ValidateFormElement {
  constructor(formElement) {
    this.formElement = formElement;

    this.isValid = false;

    if (this.formElement.type === 'checkbox' || this.formElement.type === 'radio') {
      this.formElement.addEventListener('click', event => {
        this.formElement.dispatchEvent(
          new CustomEvent(
            "validateFormElementClick",
            {
              detail: {
                formElement: this,
                clickEvent: event,
              },
              bubbles: true,
              cancelable: false
            }
          )
        );
      }, true);
    } else {
      this.formElement.addEventListener('blur', event => {
        this.formElement.dispatchEvent(
          new CustomEvent(
            "validateFormElementBlur",
            {
              detail: {
                formElement: this,
                blurEvent: event,
              },
              bubbles: true,
              cancelable: false
            }
          )
        );
      }, true);
    }
  }

  focus() {
    this.formElement.focus();
  }

  validate() {
    try {
      hasError(this.formElement);

      this.isValid = true;

      removeError(this.formElement);
    } catch (error) {
      if (error instanceof ValidationError) {
        showError(this.formElement, error.errorMessage);
      }
    }
  }
}
