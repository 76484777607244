export default function () {

  function closeToolMenus() {
    let iconMenus = document.getElementsByClassName('tool-menu');
    for (let i = 0; i < iconMenus.length; i++) {
      let iconMenu = iconMenus[i];
      iconMenu.classList.remove('open');
      let items = iconMenu.querySelectorAll('li');
      for (let i2 = 0; i2 < items.length; i2++) {
        items[i2].style.right = '0';
      }
    }
  }

  function getWidth(elem) {
    let positionInfo = elem.getBoundingClientRect();
    return positionInfo.width;
  }

  function openMenu(menu) {
    menu.classList.add('open');
    let menuItems = menu.querySelectorAll('li');
    for (let i = 0; i < menuItems.length; i++) {
      let item = menuItems[i];
      item.style.right = Math.round(getWidth(item)) * (i + 1) + 'px';
      item.style.zIndex = (menuItems.length - i);
    }
  }

  document.addEventListener('click', function () {
    closeToolMenus();
  });

  //icon menu
  let trigger = document.getElementsByClassName('tool-menu-trigger');
  for (let i = 0; i < trigger.length; i++) {
    let thisTrigger = trigger[i];
    thisTrigger.addEventListener('click', function (e) {
      e.stopPropagation();
      let menu = thisTrigger.closest('.tool-menu'),
        action = (menu.classList.contains('open')) ? 'close' : 'open';
      closeToolMenus();
      (action === 'close') ? menu.classList.remove('open') : openMenu(menu);
    })
  }
}
