/* eslint-disable */
'use strict';

export default function () {
  //BEGIN base functions
  var classCallCheck = function (instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  };

  var createClass = (function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) {
          descriptor.writable = true;
        }
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) {
        defineProperties(Constructor.prototype, protoProps);
      }
      if (staticProps) {
        defineProperties(Constructor, staticProps);
      }
      return Constructor;
    };
  })();

  var defineProperty = function (obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  };

  var inherits = function (subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) {
      Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.prototype = superClass;
    }
  };

  var possibleConstructorReturn = function (self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  };

  var toConsumableArray = function (arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  };

  //more base functions
  var addClass$1 = function addClass(element, classString) {
    return element.classList.add(classString);
  };

  var elementFromTemplate = function elementFromTemplate(string) {
    return new window.DOMParser().parseFromString(string, 'text/html').body.firstChild;
  };

  var find = function find(parent, childrenSelector) {
    return parent.querySelectorAll(childrenSelector);
  };

  var findOne = function findOne(parent, childrenSelector) {
    return parent.querySelector(childrenSelector);
  };

  var hasClass = function hasClass(element, classString) {
    return element.classList.contains(classString);
  };

  var removeClass$1 = function removeClass(element, classString) {
    return element.classList.remove(classString);
  };

  var Q = function Q(target) {
    return document.querySelectorAll(target);
  };

  var SEARCH_LOWER_LIMIT = 5;
  var LETTER_WIDTH = 8;

  var KEY_CODES = {
    up: 38,
    down: 40,
    enter: 13,
    backspace: 8
  };

  //END base functions


  var DROPDOWN_PREFIX = 'dropdown';

  var DROPDOWN = {
    type: {
      typeAheadMulti: 'MultiSelectTypeAheadDropdown',
      single: 'SingleSelectDropdown'
    },

    isType: function isType(object, type) {
      return object.constructor.name === DROPDOWN.type[type];
    },


    default: {
      class: {
        show: 'show',
        highlight: 'highlight',
        dataText: 'data-text'
      },

      selector: {
        select: '.' + DROPDOWN_PREFIX + '-select',
        group: '.' + DROPDOWN_PREFIX + '-group',
        item: '.' + DROPDOWN_PREFIX + '-item',
        search: '.' + DROPDOWN_PREFIX + '-search',
        searchInput: '.' + DROPDOWN_PREFIX + '-search-input',
        searchNotFound: '.' + DROPDOWN_PREFIX + '-search-not-found-wrapper',
        menu: '.' + DROPDOWN_PREFIX + '-menu',
        toggle: '.' + DROPDOWN_PREFIX + '-toggle',

        // toggleContent: '.' + DROPDOWN_PREFIX + '-toggle-content',
        title: '.' + DROPDOWN_PREFIX + '-title',
        row: '.' + DROPDOWN_PREFIX + '-row:not(.' + DROPDOWN_PREFIX + '-row-header)',
        headerRow: '.' + DROPDOWN_PREFIX + '-row.' + DROPDOWN_PREFIX + '-row-header',
        body: '.' + DROPDOWN_PREFIX + '-body',
        arrow: '.' + DROPDOWN_PREFIX + '-arrow',
        chipIcon: '.' + DROPDOWN_PREFIX + '-chips-plus-icon',
        sectionTitle: '.section-title'
      }
    },

    multiple: {
      class: {
        select: DROPDOWN_PREFIX + '-multiple',
        typeAhead: DROPDOWN_PREFIX + '-multiple-type-ahead'
      }
    },

    checkbox: {
      selector: {
        namedInputs: 'input[name]',
        checkAll: 'input.check-all',
        selectableCheckboxes: 'input[name]:not([disabled])',
        checkedInput: 'input[name]:checked',
        checked: 'checked'
      }
    },

    chip: {
      class: {
        chip: DROPDOWN_PREFIX + '-chip',
        removeChip: DROPDOWN_PREFIX + '-chip-remove'
      },

      selector: {
        chips: '.' + DROPDOWN_PREFIX + '-chips'
      }
    }

    /**
     * Trigger checkbox toggle event on checkbox
     *
     * @param checkbox
     */
  };
  var toggleCheckbox = function toggleCheckbox(checkbox) {
    checkbox.checked = !checkbox.checked;
    if ('createEvent' in document) {
      var evt = document.createEvent('HTMLEvents');
      evt.initEvent('change', false, true);
      checkbox.dispatchEvent(evt);
    } else {
      checkbox.fireEvent('onchange');
    }
  };

  var BaseDropdownGroupClass =

  /**
   * @param dropdownGroup should be a HTML object
   * @param dropdownObject should be a instance of Single or Multi Select Dropdowns
   */
    function BaseDropdownGroupClass(dropdownGroup, dropdownObject) {
      classCallCheck(this, BaseDropdownGroupClass);

      this.dropdownObject = dropdownObject;
      this.dropdownGroup = dropdownGroup;

      this.inputs = [];
    };

  var BaseDropdownClass1 = (function () {
    /**
     * @param dropdown should be a HTML object
     */
    function BaseDropdownClass(dropdown) {
      classCallCheck(this, BaseDropdownClass);

      this.dropdown = dropdown;
      this.dropdownGroups = find(this.dropdown, DROPDOWN.default.selector.group);
      this.dropdownSearchInput = findOne(this.dropdown, DROPDOWN.default.selector.searchInput);
      this.dropdownSearchNotFound = findOne(this.dropdown, DROPDOWN.default.selector.searchNotFound);

      // this.dropdownTitleContent = findOne(this.dropdown, DROPDOWN.default.selector.toggleContent);
      this.dropdownToggle = findOne(this.dropdown, DROPDOWN.default.selector.toggle);
      this.dropdownMenu = findOne(this.dropdown, DROPDOWN.default.selector.menu);
      this.dropdownBody = findOne(this.dropdown, DROPDOWN.default.selector.body);
      this.dropdownRows = find(this.dropdownBody, DROPDOWN.default.selector.row);
      this.dropdownInputs = find(this.dropdownBody, DROPDOWN.checkbox.selector.namedInputs);
      this.dropdownHeaderRows = find(this.dropdown, DROPDOWN.default.selector.headerRow);
      this.dropdownTitle = findOne(this.dropdown, DROPDOWN.default.selector.title);
      this.defaultTitle = this.dropdownTitle.innerHTML;

      this.groups = [];
      this.searchText = '';

      this.isSingle = DROPDOWN.isType(this, 'single');
      this.isTypeAheadMultiselect = DROPDOWN.isType(this, 'typeAheadMulti');
      this.hasSearchFunctionality = true;
    }

    createClass(BaseDropdownClass, [{
      key: 'initialize',
      value: function initialize() {
        // divide dropdown by groups
        this.initializeGroups();

        this.initializeSearchBar();
        this.updateTitle();

        this.toggleDropdownMenuEvent();
        this.bindSearchEvents();
      }
    }, {
      key: 'initializeGroups',
      value: function initializeGroups() {
      }
    }, {
      key: 'initializeSearchBar',
      value: function initializeSearchBar() {
        var items = find(this.dropdown, DROPDOWN.default.selector.item);
        if (!items || !this.dropdownSearchInput) {
          this.hasSearchFunctionality = false;
          return;
        }

        if (items.length > SEARCH_LOWER_LIMIT) {
          this.addEventListenersOnSearch();
        } else {
          this.hasSearchFunctionality = false;
          var hideElement = this.isTypeAheadMultiselect ? this.dropdownSearchInput : this.dropdownSearchInput.closest(DROPDOWN.default.selector.search);
          hideElement.style.display = 'none';
        }
      }
    }, {
      key: 'hideSearchNotFoundElement',
      value: function hideSearchNotFoundElement() {
        this.dropdownSearchNotFound.style.display = 'none';
      }
    }, {
      key: 'showSearchNotFoundElement',
      value: function showSearchNotFoundElement() {
        this.dropdownSearchNotFound.style.display = 'block';
      }
    }, {
      key: 'toggleSearchNotFound',
      value: function toggleSearchNotFound() {
        if (!this.dropdownSearchNotFound) {
          return;
        }

        var areRowsHidden = [].concat(toConsumableArray(this.dropdownRows)).every(function (row) {
          return row.style.display === 'none';
        });

        if (areRowsHidden) {
          this.showSearchNotFoundElement();
        } else {
          this.hideSearchNotFoundElement();
        }
      }
    }, {
      key: 'isMenuShown',
      value: function isMenuShown() {
        return hasClass(this.dropdownMenu, DROPDOWN.default.class.show);
      }
    }, {
      key: 'isDropdownShown',
      value: function isDropdownShown() {
        return hasClass(this.dropdown, DROPDOWN.default.class.show);
      }
    }, {
      key: 'highlightedRowKey',
      value: function highlightedRowKey() {
        var rows = [].concat(toConsumableArray(this.dropdownRows));
        var highlightRow = rows.find(function (row) {
          return hasClass(row, DROPDOWN.default.class.highlight);
        });
        var index = highlightRow === undefined ? -1 : rows.indexOf(highlightRow);
        return index === -1 ? null : index;
      }
    }, {
      key: 'removeHighlightedRow',
      value: function removeHighlightedRow() {
        var highlightedRowKey = this.highlightedRowKey();
        if (highlightedRowKey !== null) {
          removeClass$1(this.dropdownRows[highlightedRowKey], DROPDOWN.default.class.highlight);
        }
      }
    }, {
      key: 'showRows',
      value: function showRows() {
        var visibilityStatus = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        var displayValue = visibilityStatus === true ? 'block' : 'none';
        this.dropdownRows.forEach(function (row) {
          row.style.display = displayValue;
        });
      }
    }, {
      key: 'showHeaderRows',
      value: function showHeaderRows() {
        var visibilityStatus = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : true;

        var displayValue = visibilityStatus === true ? 'block' : 'none';
        this.dropdownHeaderRows.forEach(function (row) {
          row.style.display = displayValue;
        });
      }
    }, {
      key: 'inputMatchesSearchedText',
      value: function inputMatchesSearchedText(input, searchText) {
        var currentText = input.getAttribute(DROPDOWN.default.class.dataText).toLowerCase();
        var containsText = currentText.indexOf(searchText) >= 0;

        return containsText || searchText.length === 0;
      }
    }, {
      key: 'compareInputTextWithSearchedText',
      value: function compareInputTextWithSearchedText(input, searchText) {
        var display = this.inputMatchesSearchedText(input, searchText) ? 'block' : 'none';
        input.closest(DROPDOWN.default.selector.row).style.display = display;
      }
    }, {
      key: 'resetRowsVisibility',
      value: function resetRowsVisibility() {
        this.showHeaderRows();
        this.showRows();

        if (this.hasSearchFunctionality && this.isTypeAheadMultiselect) {
          this.dropdownSearchInput.removeAttribute('style');
        }
      }
    }, {
      key: 'search',
      value: function search(searchText) {
        var _this = this;

        if (searchText === this.searchText) {
          return;
        }

        if (!searchText.length) {
          this.resetRowsVisibility();
        } else {
          this.dropdownInputs.forEach(function (input) {
            _this.compareInputTextWithSearchedText(input, searchText);
          });

          if (!this.searchText.length) {
            this.showHeaderRows(false);
          }

          if (this.hasSearchFunctionality) {
            this.dropdownSearchInput.style.width = (searchText.length + 3) * LETTER_WIDTH + 'px';
          }
        }

        this.toggleSearchNotFound();
        this.searchText = searchText;
      }
    }, {
      key: 'keepDropdownRowVisible',
      value: function keepDropdownRowVisible(highlightedRowKey) {
        var highlightedRow = this.dropdownRows[highlightedRowKey];
        var scrollPosition = highlightedRow.offsetTop + highlightedRow.offsetHeight - this.dropdownBody.offsetHeight;
        this.dropdownBody.scrollTop = scrollPosition;
      }
    }, {
      key: 'nextAvailableRowOfArray',
      value: function nextAvailableRowOfArray(rows) {
        return rows.find(function (row) {
          return row.offsetHeight !== 0;
        });
      }
    }, {
      key: 'upArrowButtonFunctionality',
      value: function upArrowButtonFunctionality() {
        // change highlighted row
        var highlightedRowKey = this.highlightedRowKey();

        if (highlightedRowKey !== null) {
          // since the direction is upwards, the array of rows which in we should check will be reduced
          // the next available row is the closest visible one before the current one
          var potentialRows = [].concat(toConsumableArray(this.dropdownRows)).slice(0, highlightedRowKey).reverse();
          var nextRow = this.nextAvailableRowOfArray(potentialRows);

          if (nextRow !== undefined) {
            removeClass$1(this.dropdownRows[highlightedRowKey], DROPDOWN.default.class.highlight);
            addClass$1(nextRow, DROPDOWN.default.class.highlight);
            highlightedRowKey--;
          }

          // keep highlighted row visible
          this.keepDropdownRowVisible(highlightedRowKey);
        }
      }
    }, {
      key: 'downArrowButtonFunctionality',
      value: function downArrowButtonFunctionality() {
        // change highlighted row
        var highlightedRowKey = this.highlightedRowKey();

        if (highlightedRowKey !== null) {
          // the next available row is the closest visible one after the current one
          var potentialRows = [].concat(toConsumableArray(this.dropdownRows)).slice(highlightedRowKey + 1);
          var nextRow = this.nextAvailableRowOfArray(potentialRows);

          if (nextRow !== undefined) {
            removeClass$1(this.dropdownRows[highlightedRowKey], DROPDOWN.default.class.highlight);
            addClass$1(nextRow, DROPDOWN.default.class.highlight);
            highlightedRowKey++;
          }
        } else {
          highlightedRowKey = 0;
          addClass$1(this.dropdownRows[highlightedRowKey], DROPDOWN.default.class.highlight);
        }

        // keep highlighted row visible
        this.keepDropdownRowVisible(highlightedRowKey);
      }
    }, {
      key: 'enterButtonFunctionality',
      value: function enterButtonFunctionality() {
        var highlightedRowKey = this.highlightedRowKey();
        if (highlightedRowKey !== null) {
          var highlightedRow = this.dropdownRows[highlightedRowKey];
          var input = findOne(highlightedRow, DROPDOWN.checkbox.selector.selectableCheckboxes);
          if (input) {
            toggleCheckbox(input);
          }

          if (this.isSingle) {
            removeClass$1(this.dropdownMenu, DROPDOWN.default.class.show);
          }

          if (this.hasSearchFunctionality) {
            this.dropdownSearchInput.value = '';
            this.search(this.dropdownSearchInput);
          }

          this.updateTitle();
        }
      }
    }, {
      key: 'backspaceButtonFunctionality',
      value: function backspaceButtonFunctionality() {
        var removeChips = find(this.dropdown, '.' + DROPDOWN.chip.class.removeChip);
        if (removeChips.length) {
          removeChips[removeChips.length - 1].click();
        }
      }
    }, {
      key: 'moveThroughItemsEvent',
      value: function moveThroughItemsEvent(event) {
        var _keyCodeFunctions;

        var keyCodeFunctions = (_keyCodeFunctions = {}, defineProperty(_keyCodeFunctions, KEY_CODES.up, this.upArrowButtonFunctionality), defineProperty(_keyCodeFunctions, KEY_CODES.down, this.downArrowButtonFunctionality), defineProperty(_keyCodeFunctions, KEY_CODES.enter, this.enterButtonFunctionality), _keyCodeFunctions);

        if (!this.isDropdownShown()) {
          return;
        }
        if (!Object.keys(keyCodeFunctions).includes(event.keyCode.toString())) {
          return;
        }

        event.preventDefault();
        keyCodeFunctions[event.keyCode].call(this);
      }
    }, {
      key: 'bindSearchEvents',
      value: function bindSearchEvents() {
        var _this2 = this;

        document.addEventListener('keydown', function (event) {
          _this2.moveThroughItemsEvent(event);
        });
      }
    }, {
      key: 'closeDropdown',
      value: function closeDropdown() {
        removeClass$1(this.dropdownMenu, DROPDOWN.default.class.show);
        removeClass$1(this.dropdown, DROPDOWN.default.class.show);
        if (this.dropdownSearchInput) {
          this.dropdownSearchInput.value = '';
          this.search(this.dropdownSearchInput);
        }
        this.removeHighlightedRow();
        this.updateTitle();
      }
    }, {
      key: 'openDropdown',
      value: function openDropdown() {
        addClass$1(this.dropdownMenu, DROPDOWN.default.class.show);
        addClass$1(this.dropdown, DROPDOWN.default.class.show);
        this.updateTitle();
      }
    }, {
      key: 'addTitleIsUpClass',
      value: function addTitleIsUpClass() {
        this.dropdown.classList.add('is-active');
      }
    }, {
      key: 'removeTitleIsUpClass',
      value: function removeTitleIsUpClass() {
        this.dropdown.classList.remove('is-active');
      }
    }, {
      key: 'triggerEventOnBackspace',
      value: function triggerEventOnBackspace(event) {
        // hitting backspace when the search input is empty will remove the last chip added
        var backspaceTriggeredOnEmptySearchInput = this.isDropdownShown() && event.keyCode === KEY_CODES.backspace && this.dropdownSearchInput && !this.searchText;
        if (backspaceTriggeredOnEmptySearchInput) {
          event.preventDefault();
          this.backspaceButtonFunctionality();
        }
      }
    }, {
      key: 'triggerSearchEvent',
      value: function triggerSearchEvent() {
        var searchText = this.dropdownSearchInput.value.toLowerCase();

        // trigger the search in every group
        this.search(searchText);
      }
    }, {
      key: 'addEventListenersOnSearch',
      value: function addEventListenersOnSearch() {
        var _this3 = this;

        document.addEventListener('keydown', function (event) {
          _this3.triggerEventOnBackspace(event);
        });

        if (!this.dropdownSearchInput) {
          return;
        }

        this.dropdownSearchInput.addEventListener('keyup', function (event) {
          _this3.triggerSearchEvent();
        });
      }
    }, {
      key: 'focusOnInput',
      value: function focusOnInput() {
        if (this.dropdownSearchInput) {
          this.dropdownSearchInput.focus();
        }
      }
    }, {
      key: 'clickOnDropdownIconEvent',
      value: function clickOnDropdownIconEvent() {
        if (this.isMenuShown()) {
          return this.closeDropdown();
        }

        this.openDropdown();
        this.focusOnInput();
        return true;
      }
    }, {
      key: 'clickedOnIcon',
      value: function clickedOnIcon(event) {
        var chipIcons = [].concat(toConsumableArray(find(this.dropdown, DROPDOWN.default.selector.chipIcon)));
        if (this.isTypeAheadMultiselect) {
          return chipIcons.some(function (dropdown) {
            return dropdown === event.target;
          });
        }

        var arrow = findOne(this.dropdown, DROPDOWN.default.selector.arrow);
        return event.target === arrow;
      }
    }, {
      key: 'clickOnDropdownEvent',
      value: function clickOnDropdownEvent(event) {
        if (this.clickedOnIcon(event)) {
          this.clickOnDropdownIconEvent();
        } else {
          if (!this.isMenuShown()) {
            this.openDropdown();
          }
          this.focusOnInput();
        }
      }
    }, {
      key: 'onClickEvent',
      value: function onClickEvent(event) {
        if (this.dropdownToggle.contains(event.target)) {
          this.clickOnDropdownEvent(event);
        } else {
          this.closeDropdown();
        }
      }
    }, {
      key: 'clickOnDropdownMenuEvent',
      value: function clickOnDropdownMenuEvent(event) {
        if (this.isSingle) {
          this.closeDropdown();
        }
        event.stopPropagation();
        this.focusOnInput();
      }
    }, {
      key: 'toggleDropdownMenuEvent',
      value: function toggleDropdownMenuEvent() {
        var _this4 = this;

        document.addEventListener('click', function (event) {
          return _this4.onClickEvent(event);
        });

        this.dropdownMenu.addEventListener('click', function (event) {
          _this4.clickOnDropdownMenuEvent(event);
        });
      }
    }, {
      key: 'updateTitle',
      value: function updateTitle() {
      }
    }]);
    return BaseDropdownClass;
  })();

  var SingleSelectDropdownGroup1 = (function (_BaseDropdownGroupCla) {
    inherits(SingleSelectDropdownGroup, _BaseDropdownGroupCla);

    /**
     * @param dropdownGroup should be a HTML object
     * @param dropdownObject should be a SingleSelectDropdown instance
     */
    function SingleSelectDropdownGroup(dropdownGroup, dropdownObject) {
      classCallCheck(this, SingleSelectDropdownGroup);

      var _this = possibleConstructorReturn(this, (SingleSelectDropdownGroup.prototype || Object.getPrototypeOf(SingleSelectDropdownGroup)).call(this, dropdownGroup, dropdownObject));

      _this.populateInputs();
      _this.addEventListenersToInputs();
      return _this;
    }

    createClass(SingleSelectDropdownGroup, [{
      key: 'populateInputs',
      value: function populateInputs() {
        var _this2 = this;

        find(this.dropdownGroup, DROPDOWN.checkbox.selector.namedInputs).forEach(function (input) {
          _this2.inputs.push(input);
        });
      }
    }, {
      key: 'onCheckboxChangeEvent',
      value: function onCheckboxChangeEvent(input) {
        if (input.checked) {
          var activeItem = findOne(this.dropdownObject.dropdown, DROPDOWN.default.selector.item + '.' + DROPDOWN.checkbox.selector.checked);
          if (activeItem) {
            removeClass$1(activeItem, DROPDOWN.checkbox.selector.checked);
          }
        }
        input.closest(DROPDOWN.default.selector.item).classList.toggle(DROPDOWN.checkbox.selector.checked, input.checked);
      }
    }, {
      key: 'addEventListenersToInputs',
      value: function addEventListenersToInputs() {
        var _this3 = this;

        this.inputs.forEach(function (input) {
          return input.addEventListener('change', function () {
            return _this3.onCheckboxChangeEvent(input);
          });
        });
      }
    }]);
    return SingleSelectDropdownGroup;
  })(BaseDropdownGroupClass);

  var SingleSelectDropdownFunction = (function (_BaseDropdownClass1) {
    inherits(SingleSelectDropdown, _BaseDropdownClass1);

    /**
     * @param dropdown should be a HTML object
     */
    function SingleSelectDropdown(dropdown) {
      classCallCheck(this, SingleSelectDropdown);

      var _this4 = possibleConstructorReturn(this, (SingleSelectDropdown.prototype || Object.getPrototypeOf(SingleSelectDropdown)).call(this, dropdown));

      _this4.initialize();
      _this4.updateTitle();
      return _this4;
    }

    createClass(SingleSelectDropdown, [{
      key: 'initializeGroups',
      value: function initializeGroups() {
        var _this5 = this;

        this.dropdownGroups.forEach(function (dropdownGroup) {
          _this5.groups.push(new SingleSelectDropdownGroup1(dropdownGroup, _this5));
        });
      }

      /**
       * Selecting an option updates title
       */

    }, {
      key: 'updateTitle',
      value: function updateTitle() {
        var checkedInput = findOne(this.dropdown, DROPDOWN.checkbox.selector.checkedInput);
        this.dropdownTitle.innerHTML = checkedInput ? checkedInput.getAttribute(DROPDOWN.default.class.dataText) : this.defaultTitle;
      }
    }]);
    return SingleSelectDropdown;
  })(BaseDropdownClass1);

  /**
   * Chips are mandatory for multiple selects
   */

  var DropdownChipFunction = (function () {
    function DropdownChip(dropdownChips, dropdownCheckbox) {
      classCallCheck(this, DropdownChip);

      this.dropdownChips = dropdownChips;
      this.dropdownCheckbox = dropdownCheckbox;
      this.chip = null;

      // chip's name and text are extracted from the specific checkbox
      this.chipName = this.dropdownCheckbox.getAttribute('name');
      this.chipContent = this.dropdownCheckbox.getAttribute(DROPDOWN.default.class.dataText);

      this.createChip();
      this.closeButtonOnClickEvent();
    }

    /**
     * Chip template
     *
     * @param chipName
     * @param chipContent
     * @returns {string}
     */


    createClass(DropdownChip, [{
      key: 'createChip',
      value: function createChip() {
        // add the chip after all of the others within the group
        var chipTemplate = DropdownChip.dropdownChipTemplate(this.chipName, this.chipContent);
        this.chip = elementFromTemplate(chipTemplate);

        this.dropdownChips.insertBefore(this.chip, findOne(this.dropdownChips, DROPDOWN.default.selector.searchInput));
      }
    }, {
      key: 'closeButtonOnClickEvent',
      value: function closeButtonOnClickEvent() {
        var _this = this;

        findOne(this.chip, '.' + DROPDOWN.chip.class.removeChip).addEventListener('click', function (event) {
          event.stopPropagation();
          toggleCheckbox(_this.dropdownCheckbox);
        });
      }
    }], [{
      key: 'dropdownChipTemplate',
      value: function dropdownChipTemplate(chipName, chipContent) {
        return '\n        <div class="' + DROPDOWN.chip.class.chip + '" data-name="' + chipName + '">\n          ' + chipContent + '\n          <i class="icon ' + DROPDOWN.chip.class.removeChip + '" aria-hidden="true"></i>\n        </div>\n      ';
      }
    }]);
    return DropdownChip;
  })();

  var MultiSelectCheckMarkDropdownGroupFunction = (function (_BaseDropdownGroupCla) {
    inherits(MultiSelectCheckMarkDropdownGroup, _BaseDropdownGroupCla);

    /**
     * @param dropdownGroup should be a HTML object
     * @param dropdownObject should be a MultiSelectCheckMarkDropdown instance
     */
    function MultiSelectCheckMarkDropdownGroup(dropdownGroup, dropdownObject) {
      classCallCheck(this, MultiSelectCheckMarkDropdownGroup);

      var _this2 = possibleConstructorReturn(this, (MultiSelectCheckMarkDropdownGroup.__proto__ || Object.getPrototypeOf(MultiSelectCheckMarkDropdownGroup)).call(this, dropdownGroup, dropdownObject));

      // TODO: Find a way to remove the use of '__promo__' to satisfy lint:js rules
      // console.log('MultiSelectCheckMarkDropdownGroup.__proto__: ', MultiSelectCheckMarkDropdownGroup.__proto__);
      // console.log('MultiSelectCheckMarkDropdownGroup.prototype: ', MultiSelectCheckMarkDropdownGroup.prototype);

      _this2.checkAllInput = findOne(dropdownGroup, DROPDOWN.checkbox.selector.checkAll);

      _this2.numChecked = 0;

      _this2.initialize();
      _this2.bindEvents();
      return _this2;
    }

    createClass(MultiSelectCheckMarkDropdownGroup, [{
      key: 'initialize',
      value: function initialize() {
        var _this3 = this;

        find(this.dropdownGroup, DROPDOWN.checkbox.selector.selectableCheckboxes).forEach(function (checkbox) {
          _this3.inputs.push(checkbox);

          if (checkbox.checked) {
            addClass$1(checkbox.closest(DROPDOWN.default.selector.item), DROPDOWN.checkbox.selector.checked);
          }
        });
      }
    }, {
      key: 'markCheckboxChecked',
      value: function markCheckboxChecked(checkbox) {
        var item = checkbox.closest(DROPDOWN.default.selector.item);
        removeClass$1(item, DROPDOWN.checkbox.selector.checked);
      }
    }, {
      key: 'markCheckboxUnchecked',
      value: function markCheckboxUnchecked(checkbox) {
        var item = checkbox.closest(DROPDOWN.default.selector.item);
        addClass$1(item, DROPDOWN.checkbox.selector.checked);
      }
    }, {
      key: 'addEventListenersToCheckAllInputs',
      value: function addEventListenersToCheckAllInputs() {
        var _this4 = this;

        this.checkAllInput.addEventListener('click', function (event) {
          var checkAllState = _this4.checkAllInput.checked;
          _this4.inputs.forEach(function (checkbox) {
            if (checkbox.checked !== checkAllState) {
              toggleCheckbox(checkbox);
            }
          });
          _this4.numChecked = checkAllState ? _this4.inputs.length : 0;
        });
      }
    }, {
      key: 'onCheckboxChangeEvent',
      value: function onCheckboxChangeEvent(checkbox) {
        var checkState = checkbox.checked;

        if (checkState) {
          this.markCheckboxUnchecked(checkbox);
          this.numChecked++;
          if (this.checkAllInput && this.numChecked === this.inputs.length) {
            this.checkAllInput.checked = checkState;
          }
        } else {
          this.markCheckboxChecked(checkbox);
          this.numChecked--;
          if (this.checkAllInput) {
            this.checkAllInput.checked = checkState;
          }
        }
        this.dropdownObject.updateTitle();
      }
    }, {
      key: 'addEventListenersToInputs',
      value: function addEventListenersToInputs() {
        var _this5 = this;

        this.inputs.forEach(function (checkbox) {
          return checkbox.addEventListener('change', function () {
            return _this5.onCheckboxChangeEvent(checkbox);
          });
        });
      }
    }, {
      key: 'bindEvents',
      value: function bindEvents() {
        if (this.checkAllInput) {
          this.addEventListenersToCheckAllInputs();
        }
        this.addEventListenersToInputs();
      }
    }]);
    return MultiSelectCheckMarkDropdownGroup;
  })(BaseDropdownGroupClass);

  var MultiSelectTypeAheadDropdownGroupFunction = (function (_BaseDropdownGroupCla2) {
    inherits(MultiSelectTypeAheadDropdownGroup, _BaseDropdownGroupCla2);

    /**
     * @param dropdownGroup should be a HTML object
     * @param dropdownObject should be a MultiSelectTypeAheadDropdown instance
     */
    function MultiSelectTypeAheadDropdownGroup(dropdownGroup, dropdownObject) {
      classCallCheck(this, MultiSelectTypeAheadDropdownGroup);

      var _this6 = possibleConstructorReturn(this, (MultiSelectTypeAheadDropdownGroup.__proto__ || Object.getPrototypeOf(MultiSelectTypeAheadDropdownGroup)).call(this, dropdownGroup, dropdownObject));

      _this6.dropdownChips = dropdownObject.dropdownChips;

      _this6.initializeGroups();
      _this6.bindEvents();
      return _this6;
    }

    createClass(MultiSelectTypeAheadDropdownGroup, [{
      key: 'initializeGroups',
      value: function initializeGroups() {
        var _this7 = this;

        find(this.dropdownGroup, DROPDOWN.checkbox.selector.selectableCheckboxes).forEach(function (checkbox) {
          _this7.inputs.push({
            input: checkbox,
            chip: null
          });

          if (checkbox.checked) {
            _this7.inputs[_this7.inputs.length - 1].chip = new DropdownChipFunction(_this7.dropdownChips, checkbox);
            addClass$1(checkbox.closest(DROPDOWN.default.selector.item), DROPDOWN.checkbox.selector.checked);
          }
        });
      }
    }, {
      key: 'markCheckboxChecked',
      value: function markCheckboxChecked(checkbox) {
        var item = checkbox.input.closest(DROPDOWN.default.selector.item);
        checkbox.chip.chip.remove();
        delete checkbox.chip;
        removeClass$1(item, DROPDOWN.checkbox.selector.checked);
      }
    }, {
      key: 'markCheckboxUnchecked',
      value: function markCheckboxUnchecked(checkbox) {
        var item = checkbox.input.closest(DROPDOWN.default.selector.item);
        checkbox.chip = new DropdownChipFunction(this.dropdownChips, checkbox.input);
        addClass$1(item, DROPDOWN.checkbox.selector.checked);
      }
    }, {
      key: 'onCheckboxChangeEvent',
      value: function onCheckboxChangeEvent(checkbox) {
        var checkState = checkbox.input.checked;

        if (checkState) {
          this.markCheckboxUnchecked(checkbox);
        } else {
          this.markCheckboxChecked(checkbox);
        }
        this.dropdownObject.updateTitle();
      }
    }, {
      key: 'addEventListenersToInputs',
      value: function addEventListenersToInputs() {
        var _this8 = this;

        this.inputs.forEach(function (checkbox) {
          return checkbox.input.addEventListener('change', function () {
            return _this8.onCheckboxChangeEvent(checkbox);
          });
        });
      }
    }, {
      key: 'bindEvents',
      value: function bindEvents() {
        this.addEventListenersToInputs();
      }
    }]);
    return MultiSelectTypeAheadDropdownGroup;
  })(BaseDropdownGroupClass);

  var MultiSelectTypeAheadDropdownFunction = (function (_BaseDropdownClass1) {
    inherits(MultiSelectTypeAheadDropdown, _BaseDropdownClass1);

    /**
     * @param dropdown should be a HTML object
     */
    function MultiSelectTypeAheadDropdown(dropdown) {
      classCallCheck(this, MultiSelectTypeAheadDropdown);

      var _this9 = possibleConstructorReturn(this, (MultiSelectTypeAheadDropdown.__proto__ || Object.getPrototypeOf(MultiSelectTypeAheadDropdown)).call(this, dropdown));

      _this9.dropdownChips = findOne(_this9.dropdown, DROPDOWN.chip.selector.chips);

      _this9.initialize();
      return _this9;
    }

    createClass(MultiSelectTypeAheadDropdown, [{
      key: 'initializeGroups',
      value: function initializeGroups() {
        var _this10 = this;

        this.dropdownGroups.forEach(function (dropdownGroup) {
          _this10.groups.push(new MultiSelectTypeAheadDropdownGroupFunction(dropdownGroup, _this10));
        });
      }
    }, {
      key: 'showChips',
      value: function showChips() {
        // this.dropdownTitleContent.style.display = 'none';
        this.dropdownChips.style.display = 'block';
      }
    }, {
      key: 'hideChips',
      value: function hideChips() {
        // this.dropdownTitleContent.style.display = 'flex';
        this.dropdownChips.style.display = 'none';
      }

      /**
       * If no items are selected, the default title will be shown
       */

    }, {
      key: 'updateTitle',
      value: function updateTitle() {
        var showChips = findOne(this.dropdownChips, '.' + DROPDOWN.chip.class.chip) || this.isMenuShown();

        if (showChips) {
          this.showChips();
          this.addTitleIsUpClass();
          if (this.dropdownSearchInput) {
            this.dropdownSearchInput.focus();
          }
        } else {
          this.hideChips();
          this.removeTitleIsUpClass();
        }
        this.removeHighlightedRow();
      }
    }]);
    return MultiSelectTypeAheadDropdown;
  })(BaseDropdownClass1);

  var MultiSelectCheckMarkDropdownFunction = (function (_BaseDropdownClass2) {
    inherits(MultiSelectCheckMarkDropdown, _BaseDropdownClass2);

    /**
     * @param dropdown should be a HTML object
     */
    function MultiSelectCheckMarkDropdown(dropdown) {
      classCallCheck(this, MultiSelectCheckMarkDropdown);

      var _this11 = possibleConstructorReturn(this, (MultiSelectCheckMarkDropdown.__proto__ || Object.getPrototypeOf(MultiSelectCheckMarkDropdown)).call(this, dropdown));

      _this11.initialize();
      return _this11;
    }

    createClass(MultiSelectCheckMarkDropdown, [{
      key: 'initializeGroups',
      value: function initializeGroups() {
        var _this12 = this;

        this.dropdownGroups.forEach(function (dropdownGroup) {
          _this12.groups.push(new MultiSelectCheckMarkDropdownGroupFunction(dropdownGroup, _this12));
        });
      }

      /**
       * If no items are selected, the default title will be shown
       */

    }, {
      key: 'updateTitle',
      value: function updateTitle() {
        var checkedInput = find(this.dropdown, DROPDOWN.checkbox.selector.checkedInput);

        var titleText = this.defaultTitle;
        if (checkedInput.length > 0) {
          titleText = checkedInput.length === 1 ? checkedInput[0].getAttribute(DROPDOWN.default.class.dataText) : checkedInput.length + ' items selected';
          var selectedCheckAll = find(this.dropdown, DROPDOWN.default.selector.headerRow + ' ' + DROPDOWN.checkbox.selector.checkAll + ':' + DROPDOWN.checkbox.selector.checked);
          if (selectedCheckAll.length === 1) {
            var group = selectedCheckAll[0].closest(DROPDOWN.default.selector.group);
            if (find(group, DROPDOWN.checkbox.selector.selectableCheckboxes).length === checkedInput.length) {
              titleText = findOne(group, DROPDOWN.default.selector.sectionTitle).innerHTML;
            }
          }
        }

        this.dropdownTitle.innerHTML = titleText;
        this.removeHighlightedRow();
      }
    }]);
    return MultiSelectCheckMarkDropdown;
  })(BaseDropdownClass1);

  var initialized = false;

  var loadDropdowns = (function () {

    if (initialized === true) {
      return;
    }

    var dropdowns = [].concat(toConsumableArray(Q(DROPDOWN.default.selector.select)));
    dropdowns.forEach(function (dropdown) {
      if (hasClass(dropdown, DROPDOWN.multiple.class.select)) {
        if (hasClass(dropdown, DROPDOWN.multiple.class.typeAhead)) {
          return new MultiSelectTypeAheadDropdownFunction(dropdown);
        } else {
          return new MultiSelectCheckMarkDropdownFunction(dropdown);
        }
      } else {
        return new SingleSelectDropdownFunction(dropdown);
      }
    });

    initialized = true;
  });
  loadDropdowns();
}
