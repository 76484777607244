'use strict';

const DEFAULT_TEXTAREA_EXPAND_CLASS = "textarea-expand";

export default class Textarea {
  constructor(textarea, selector) {
    this.selector = selector;

    this.textarea = textarea;

    this.textarea.addEventListener('focus', event => {
      event.preventDefault();

      this.textarea.dispatchEvent(
        new CustomEvent(
          "textareaFocus",
          {
            detail: {
              textarea: this,
              focusEvent: event,
            },
            bubbles: true,
            cancelable: true
          }
        )
      );
    }, false);

    this.textarea.addEventListener('input', event => {
      event.preventDefault();

      this.textarea.dispatchEvent(
        new CustomEvent(
          "textareaInput",
          {
            detail: {
              textarea: this,
              focusEvent: event,
            },
            bubbles: true,
            cancelable: true
          }
        )
      );
    }, false);
  }

  onFocus(event) {
    const textarea = event.detail.textarea;
    if (textarea.triggered) { return; }
    textarea.triggered = true;
    textarea.textarea.baseScrollHeight = textarea.textarea.scrollHeight;
  }

  onChange(event) {
    const textarea = event.detail.textarea;
    const dataset = textarea.textarea.dataset;
    let minRows = (typeof dataset.minRows === 'undefined' ? 0 : dataset.minRows);
    let maxRows = (typeof dataset.maxRows === 'undefined' ? 5 : dataset.maxRows);
    let currentRows = Math.ceil((textarea.textarea.scrollHeight - textarea.textarea.baseScrollHeight) / 16);

    if (textarea.textarea.value === '') {
      textarea.textarea.rows = minRows;
    } else if (currentRows >= maxRows) {
      textarea.textarea.rows = maxRows;
    } else if (currentRows < minRows) {
      textarea.textarea.rows = minRows;
    } else {
      textarea.textarea.rows = currentRows;
    }
  }

  static init(selector = DEFAULT_TEXTAREA_EXPAND_CLASS) {
    const textareas = document.querySelectorAll(`.${selector}`);

    Textarea.addEventListeners(selector);

    return [...textareas].map(textarea => new Textarea(textarea, selector));
  }

  static addEventListeners() {
    document.addEventListener('textareaFocus', function (event) {
      event.detail.textarea.onFocus(event);
    }, true);
    document.addEventListener('textareaInput', function (event) {
      event.detail.textarea.onChange(event);
    }, true);
  }
}
