export default function () {

  let components = {
    starRatingsInteractive : `
    <div class="star-ratings-wrapper d-flex align-items-center"><div class="dynamic star-ratings-interactive text-class"></div></div>
  `,
    starRatingsStatic : `
    <div class="star-ratings-wrapper d-flex align-items-center">
      <div class="star-number">3.5</div>
      <div class="star-ratings-static dynamic" data-rating="3.5"></div>
      <div class="star-reviews">88 Reviews</div>
    </div>
  `,
    datepicker : `
    <div class="form-group bmd-form-group">
      <label for="exampleInputEmail1" class="bmd-label-floating">Date Range</label>
      <input class="form-control datepicker datepicker-range dynamic" value="">
    </div>
  `,
    quantity : `
    <div class="quantity-selector dynamic">
      <input type="number" min="0" max="9" step="3" value="3" />
      <label for="exampleInputEmail1">How Many?</label>
    </div>
  `,
    textarea : `
    <div class="form-group bmd-form-group">
      <textarea id="exampleTextArea" class="form-control textarea-expand" rows='2' data-min-rows='2' placeholder=""></textarea>
      <label for="exampleTextArea" class="bmd-label-floating">Text Area Label</label>
      <span class="bmd-caption">Keep typing as much as you want. This is a textarea.</span>
    </div>
  `,
    inlineEdit : `
      <div class="form-group bmd-form-group inline-edit dynamic">
        <input type="email" class="form-control" id="exampleInputEmail1" value="unstoppableforce@immovableobject.com" disabled>
        <svg class="icon icon-sm"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-pencil"></use></svg>
        <label for="exampleInputEmail1" class="bmd-label-floating">Email address</label>
        <span class="bmd-caption">We'll never share your email with anyone else.</span>
      </div>
    `,
    styledSelect : `
    <div class="select-styled alt dynamic">
        <select>
            <option>Resident</option>
            <option>Move</option>
            <option>Another App</option>
        </select>
    </div>
    `,
    carousel : `
    <div id="carousel-name1" class="carousel slide indicators dynamic">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <div class="carousel-caption d-none d-md-block">
                    Hi, I am image caption 1.
                </div>
                <img src="//placebear.com/500/300" alt="First slide">
            </div>
            <div class="carousel-item">
                <div class="carousel-caption d-none d-md-block">
                    Hi, I am image caption 2.
                </div>
                <img src="//placebear.com/500/300" alt="Second slide">
            </div>
            <div class="carousel-item">
                <div class="carousel-caption d-none d-md-block">
                    Hi, I am image caption 3.
                </div>
                <img src="//placebear.com/500/300" alt="Third slide">
            </div>
            <div class="carousel-item video">
                <iframe width="500" height="300" src="//www.youtube.com/embed/w9P50FHlEiA" frameborder="0" allow="autoplay; encrypted-media" allowfullscreen></iframe>
                <img src="//img.youtube.com/vi/w9P50FHlEiA/0.jpg">
            </div>
            <div class="carousel-item">
                <div class="carousel-caption d-none d-md-block">
                    Hi, I am image caption 5.
                </div>
                <img src="//placebear.com/500/300" alt="Fifth slide">
            </div>
            <div class="carousel-item">
                <div class="carousel-caption d-none d-md-block">
                    Hi, I am image caption 6.
                </div>
                <img src="//placebear.com/500/300" alt="sixth slide">
            </div>
            <div class="carousel-item">
                <div class="carousel-caption d-none d-md-block">
                    Hi, I am image caption 7.
                </div>
                <img src="//placebear.com/500/300" alt="Seventh slide">
            </div>
            <div class="carousel-item">
                <div class="carousel-caption d-none d-md-block">
                    Hi, I am image caption 8.
                </div>
                <img src="//placebear.com/500/300" alt="Eighth slide">
            </div>
            <div class="carousel-item">
                <div class="carousel-caption d-none d-md-block">
                    Hi, I am image caption 9.
                </div>
                <img src="//placebear.com/500/300" alt="Ninth slide">
            </div>
            <div class="carousel-item">
                <div class="carousel-caption d-none d-md-block">
                    Hi, I am image caption 10.
                </div>
                <img src="//placebear.com/500/300" alt="Tenth slide">
            </div>
            <div class="carousel-item">
                <div class="carousel-caption d-none d-md-block">
                    Hi, I am image caption 11.
                </div>
                <img src="//placebear.com/500/300" alt="Eleventh slide">
            </div>
            <div class="carousel-item">
                <div class="carousel-caption d-none d-md-block">
                    Hi, I am image caption 12.
                </div>
                <img src="//placebear.com/500/300" alt="twelfth slide">
            </div>
        </div>
        <div class="carousel-controls">
        </div>
        <a class="carousel-control-prev" href="#carousel-name1" role="button" data-slide="prev">
            <svg class="icon icon-sm"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-left-arrow"></use></svg>
            <span class="sr-only">Previous</span>
        </a>
        <a class="carousel-control-next" href="#carousel-name1" role="button" data-slide="next">
            <svg class="icon icon-sm"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-right-arrow"></use></svg>
            <span class="sr-only">Next</span>
        </a>
    </div>
    `,
    aside : `
      <div class="rjl-wrapper p-0">
          <button type="button" class="btn btn-primary m-2" data-toggle="aside-right" data-target="#exampleAsideRight">
              Launch demo Aside
          </button>
      
          <!-- Modal -->
          <aside class="aside right-aside dynamic" id="exampleAsideRight" aria-labelledby="exampleAsideLabel">
              <div>
                  <div class="card-header border-bottom d-flex">
                      <span class="fw-500 fs-16">Right Aside</span>
                      <a href="#" class="close-aside ml-auto" data-dismiss="#exampleAsideRight" aria-label="Close">
                          <span aria-hidden="true"><svg class="icon icon-close"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-close"></use></svg></span>
                      </a>
                  </div>
              </div>
              <div class="aside-body">
                  <div class="form-group">
                      <label>Category</label>
                      <ul class="list-unstyled radio-group">
                          <li>
                              <div class="form-check ripple">
                                  <input class="form-check-input" type="checkbox" name="exampleCheckboxGroup" id="exampleCheckboxGroup1" value="option1">
                                  <label class="form-check-label" for="exampleCheckboxGroup1">
                                      Appliance
                                  </label>
                              </div>
                          </li>
                          <li>
                              <div class="form-check ripple">
                                  <input class="form-check-input" type="checkbox" name="exampleCheckboxGroup" id="exampleCheckboxGroup2" value="option2">
                                  <label class="form-check-label" for="exampleCheckboxGroup2">
                                      Doors & Locks
                                  </label>
                              </div>
                          </li>
                          <li>
                              <div class="form-check ripple">
                                  <input class="form-check-input" type="checkbox" name="exampleCheckboxGroup" id="exampleCheckboxGroup3" value="option2">
                                  <label class="form-check-label" for="exampleCheckboxGroup3">
                                      Electrical & Lighting
                                  </label>
                              </div>
                          </li>
                          <li>
                              <div class="form-check ripple">
                                  <input class="form-check-input" type="checkbox" name="exampleCheckboxGroup" id="exampleCheckboxGroup4" value="option2">
                                  <label class="form-check-label" for="exampleCheckboxGroup4">
                                      Flooring
                                  </label>
                              </div>
                          </li>
                          <li>
                              <div class="form-check ripple">
                                  <input class="form-check-input" type="checkbox" name="exampleCheckboxGroup" id="exampleCheckboxGroup5" value="option2">
                                  <label class="form-check-label" for="exampleCheckboxGroup5">
                                      Heating & Cooling
                                  </label>
                              </div>
                          </li>
                          <li>
                              <div class="form-check ripple">
                                  <input class="form-check-input" type="checkbox" name="exampleCheckboxGroup" id="exampleCheckboxGroup6" value="option2">
                                  <label class="form-check-label" for="exampleCheckboxGroup6">
                                      Plumbing & Bath
                                  </label>
                              </div>
                          </li>
                      </ul>
                  </div>
              </div>
              <div class="aside-footer d-flex justify-content-between">
                  <a href="#" class="btn btn-secondary flex-grow-1 mr-1 close-aside" data-dismiss="#exampleAsideRight">Close</a>
                  <a href="#" class="btn btn-primary flex-grow-1 ml-1">Do Something</a>
              </div>
          </aside>
      </div>
    `,
    pagination : `
      <nav aria-label="Page navigation example">
          <ul class="pagination dynamic">
              <li class="page-item page-control control-prev disabled" data-direction="prev"><a class="page-link" href="#">Previous</a></li>
              <li class="page-item page-number active"><a class="page-link" href="#">1</a></li>
              <li class="page-item page-number"><a class="page-link" href="#">2</a></li>
              <li class="page-item page-number"><a class="page-link" href="#">3</a></li>
              <li class="page-item page-number"><a class="page-link" href="#">4</a></li>
              <li class="page-item page-number"><a class="page-link" href="#">5</a></li>
              <li class="page-item page-number"><a class="page-link" href="#">6</a></li>
              <li class="page-item page-control control-next" data-direction="next"><a class="page-link" href="#">Next</a></li>
          </ul>
      </nav>
    `,
    dropzone : `
    <form id="myId" action="/upload-target" class="dropzone dz-clickable dynamic">
      <div class="dz-default dz-message">
          <span class="dz-message-title">Drag & Drop</span>
          <span>your files here, or <span class="text-primary">browse</span></span>
      </div>
      <a class="btn btn-primary js-start-upload dz-upload-button" id="submit-all">
          <i class="glyphicon glyphicon-upload"></i>
          <span>Start upload</span>
      </a>
  </form>
    `,
    leftNav : `
    <nav class="left-navbar d-none d-lg-block card no-radius mb-0 modal-blur fs-16">
        <div class="logo-wrapper">
            <img class="rentjoy-logo" src="/images/rentjoy.svg" />
    
        </div>
        <ul class="nav nav-vertical dynamic flex-column position-relative">
            <li>
                <div class="nav-link active">
                    <svg class="menu-icon icon hover-primary"><use xlink:href="#icon-sunny"></use></svg>
                    <a href="#">Today</a>
                </div>
            </li>
            <li>
                <div class="nav-link submenu-link collapsed" data-toggle="collapse" data-target="#searchMenu">
                    <svg class="menu-icon icon hover-primary"><use xlink:href="#icon-file-search"></use></svg>
                    <a href="#">
                        Search & Lease
                        <div class="expand-arrow ml-auto">
                            <div class="arrow-left"></div>
                            <div class="arrow-right"></div>
                        </div>
                    </a>
                </div>
                <div id="searchMenu" class="sub-menu collapse">
                    <ul class="nav flex-column w-100">
                        <li><a href="#">Search Preferences</a></li>
                        <li><a href="#">Recently Viewed</a></li>
                        <li><a href="#">Property Tours</a></li>
                        <li><a href="#">Favorites</a></li>
                        <li><a href="#">Quotes &amp; Applications</a></li>
                    </ul>
                </div>
            </li>
            <li>
                <div class="nav-link action">
                    <svg class="menu-icon icon hover-primary"><use xlink:href="#icon-tools"></use></svg>
                    <a href="#">Maintenance</a>
                </div>
            </li>
            <li>
                <div class="nav-link action">
                    <svg class="menu-icon icon hover-primary"><use xlink:href="#icon-user"></use></svg>
                    <a href="#">Renter Profile</a>
                </div>
            </li>
            <li>
                <div class="nav-link">
                    <svg class="menu-icon icon hover-primary"><use xlink:href="#icon-question-mark"></use></svg>
                    <a href="#">Help</a>
                </div>
            </li>
        </ul>
       <div class="navbar-footer">
           <!--<div class="border-top py-3">-->
               <!--<div class="d-flex justify-content-around">-->
                   <!--<a href="#"><svg class="icon hover-primary"><use xlink:href="#icon-envelope"></use></svg></a>-->
                   <!--<a href="#"><svg class="icon hover-primary"><use xlink:href="#icon-phone"></use></svg></a>-->
                   <!--<a href="#"><svg class="icon hover-primary"><use xlink:href="#icon-chat"></use></svg></a>-->
               <!--</div>-->
           <!--</div>-->
       </div>
    </nav>
    <!-- end desktop navbar -->
    
    <nav class="mobile-navbar d-flex d-lg-none">
        <a class="ripple nav-link text-secondary active" href="#">
            <div class="mobile-nav-content">
                <svg class="icon"><use xlink:href="#icon-sunny"></use></svg>
                <div class="fs-xsmall">Today</div>
            </div>
        </a>
        <a class="ripple nav-link text-secondary" href="#">
            <div class="mobile-nav-content">
                <svg class="icon"><use xlink:href="#icon-dollar-sign"></use></svg>
                <div class="fs-xsmall">Payments</div>
            </div>
        </a>
        <a class="ripple nav-link text-secondary" href="#">
            <div class="mobile-nav-content">
                <svg class="icon"><use xlink:href="#icon-tools"></use></svg>
                <div class="fs-xsmall">Requests</div>
            </div>
        </a>
        <a class="ripple nav-link text-secondary" href="#">
            <div class="mobile-nav-content">
                <svg class="icon"><use xlink:href="#icon-user"></use></svg>
                <div class="fs-xsmall">Profile</div>
            </div>
        </a>
        <a class="ripple nav-link text-secondary mobile-menu-toggle" href="#" data-toggle="aside-nav" data-target="#mobileMenuAside">
            <div class="mobile-nav-content">
                <svg class="icon"><use xlink:href="#icon-menu"></use></svg>
                <div class="fs-xsmall">More</div>
            </div>
        </a>
    </nav>
    <aside id="mobileMenuAside" class="mobile-more-menu d-lg-none">
        <ul class="list-unstyled">
            <li>
                <div class="submenu">
                    <div class="submenu-link nav-link border-bottom d-flex submenu-link collapsed" data-toggle="collapse" data-target="#searchMenu2">
                        <a class="d-flex w-100 ripple" href="#">
                            <div class="avatar avatar-sm mr-3"><img src="/images/building3.png"/></div>
                            <div>
                                <h4 class="m-0 header-sm">Jameson by the Lakes</h4>
                                <p class="d-block fs-regular text-secondary mb-0 lh-1">Community Info</p>
                            </div>
                        </a>
                    </div>
                    <div id="searchMenu2" class="sub-menu collapse">
                        <ul class="nav flex-column">
                            <li><a href="#">Search Preferences</a></li>
                            <li><a href="#">Recently Viewed</a></li>
                            <li><a href="#">Property Tours</a></li>
                            <li><a href="#">Favorites</a></li>
                            <li><a href="#">Quotes & Applications</a></li>
                        </ul>
                    </div>
                </div>
            </li>
            <li>
                <div class="submenu-link nav-link border-bottom d-flex submenu-link">
                    <a class="w-100 ripple" href="#">
                        <p class="d-block fs-regular my-2">I'm a link</p>
                    </a>
                </div>
            </li>
            <li>
                <div class="submenu-link nav-link border-bottom d-flex submenu-link">
                    <a class="w-100 ripple" href="#">
                        <p class="d-block fs-regular my-2">I'm Another link</p>
                    </a>
                </div>
            </li>
        </ul>
    </aside>
    `,
    bulkEdit : `
    <div class="card card-bulk-edit text-left animate dynamic">
          <div class="card-body">
              <form action="" id="profile-info" class="mt-3 js-bulk-edit-form-wrapper dynamic rjl-js-validation form-validate">
                  <div class="bulk-edit">
                      <h3 class="header-md editing-is-hidden">Jackie Vaughan's Profile</h3>
                      <a id="profile-info-toggleEdit" class="toggle-link btn btn-text mt-0">
                          <span class="edit">Edit</span>
                          <span class="cancel">Cancel</span>
                      </a>
                      <div class="editing-is-visible">
                          <div class="form-group bmd-form-group bulk-edit-group">
                              <input type="input" class="form-control" id="firstName" value="Jackie" disabled required>
                              <label for="firstName" class="bmd-label-floating">First Name</label>
                          </div>
                          <div class="form-group bmd-form-group bulk-edit-group mb-4">
                              <input type="input" class="form-control" id="lastName" value="Vaughan" disabled required>
                              <label for="lastName" class="bmd-label-floating">Last Name</label>
                          </div>
                      </div>
                      <div id="address-info">
                          <h4 class="header-xs mb-0">Addresses</h4>
                          <div class="row mb-3">
                              <div class="col-sm-4">
                                  <div class="form-group bmd-form-group is-filled label-no-float bulk-edit-group category-is-editable">
                                      <select class="form-control" id="addressStreetSource1" disabled>
                                          <option>Home</option>
                                          <option>Work</option>
                                          <option>Other</option>
                                      </select>
                                      <svg class="icon icon-down-arrow"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-down-arrow"></use></svg>
                                      <label for="addressStreetSource1" class="bmd-label-floating">Example select</label>
                                  </div>
                              </div>
                              <div class="col-sm-8">
                                  <div class="form-group bmd-form-group bulk-edit-group">
                                      <input type="input" class="form-control" id="addressStreet1" value="2515 Pear Wood Ln" disabled required>
                                      <label for="addressStreet1" class="bmd-label-floating">Street Address</label>
                                  </div>
                                  <div class="form-group bmd-form-group bulk-edit-group">
                                      <input type="input" class="form-control" id="addressCity1" value="The Colony" disabled required>
                                      <label for="addressCity1" class="bmd-label-floating">City</label>
                                  </div>
                                  <div class="row">
                                      <div class="col-md-6">
                                          <div class="form-group bmd-form-group bulk-edit-group">
                                              <input type="text" class="form-control" id="addressState1" value="Tx" disabled required>
                                              <label for="addressState1" class="bmd-label-floating">State</label>
                                          </div>
                                      </div>
                                      <div class="col-md-6">
                                          <div class="form-group bmd-form-group bulk-edit-group">
                                              <input type="number" class="form-control" id="addressZipCode1" value="75058" disabled required>
                                              <label for="addressZipCode1" class="bmd-label-floating">Zipcode</label>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <button class="btn btn-primary editing-is-visible align-center js-bulk-edit-submit disabled">
                          Update My Profile
                      </button>
                  </div>
              </form>
          </div>
      </div>
    `
  };

  let addComponent = document.getElementById('addComponent');
  if (addComponent) {
    addComponent.addEventListener("click", function () {
      // let htmlStr = '<div class="star-ratings-wrapper d-flex align-items-center"><div class="dynamic star-ratings-interactive text-class"></div></div>';
      let wrapper = document.createElement('div');
      wrapper.innerHTML = components.bulkEdit;
      document.getElementById('components').appendChild(wrapper);
    });
  }
  let addBSComponent = document.getElementById('addBSComponent');
  if (addBSComponent) {
    addBSComponent.addEventListener("click", function () {
      let htmlStr = '<div class="form-group bmd-form-group"><input type="email" class="form-control" id="exampleInputEmail1" value=""><label for="exampleInputEmail1" class="bmd-label-floating">Email address</label><span class="bmd-caption">We&apos;ll never share your email with anyone else.</span></div>';
      let wrapper = document.createElement('div');
      wrapper.innerHTML = htmlStr;
      document.getElementById('bsComponents').appendChild(wrapper);
    });
  }


}
