'use strict';

export default function () {
  let buttonTriggers = document.getElementsByClassName("js-enable-button");

  for (let i = 0; i < buttonTriggers.length; i++) {
    let el = buttonTriggers[i],
      thisTarget = el.dataset.enabletarget,
      thisTargetElement = document.getElementById(thisTarget);

    if (el !== null) {
      //Set initial state of button as disabled
      thisTargetElement.setAttribute("disabled", "");

      el.onclick = function () {
        //Listen to conditional trigger to test if button should be disabled or not
        if (el.checked) {
          thisTargetElement.disabled = false;
        } else {
          thisTargetElement.setAttribute("disabled", "");
        }
      }
    }
  }
}
