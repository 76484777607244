'use strict';

import ValidationError from './error.js';

// Custom function to check for exact value match
function checkPasswordMatch(id) {
  if (id === 'confirm_password') {
    if (document.getElementById('password').value ===
      document.getElementById('confirm_password').value) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

// Validate the field
export function hasError(field) {

  // Don't validate submits, buttons, file and reset inputs, and disabled fields
  if (field.disabled || field.type === 'file' || field.type === 'reset' || field.type === 'submit' || field.type === 'button') { return false; }

  // Get validity
  var validity = field.validity;

  var passwordMatch = checkPasswordMatch(field.id);

  // If valid, return null
  if (validity.valid && passwordMatch) { return false; }

  // If field is required and empty
  if (validity.valueMissing) { throw new ValidationError('Please fill out this field.', field); }

  // If not the right type
  if (validity.typeMismatch) {
    // Email
    if (field.type === 'email') { throw new ValidationError('Please enter an email address.', field); }

    // URL
    if (field.type === 'url') { throw new ValidationError('Please enter a URL.', field); }
  }

  // If too short
  if (validity.tooShort) {
    throw new ValidationError(
      `Please lengthen this text to ${field.getAttribute('minLength')} characters or more. You are currently using ${field.value.length} characters.`,
      field
    );
  }

  // If too long
  if (validity.tooLong) {
    throw new ValidationError(
      `Please shorten this text to no more than ${field.getAttribute('maxLength')} characters. You are currently using ${field.value.length} characters.`,
      field
    );
  }

  // If number input isn't a number
  if (validity.badInput) { throw new ValidationError('Please enter a number.', field); }

  // If a number value doesn't match the step interval
  if (validity.stepMismatch) { throw new ValidationError('Please select a valid value.', field); }

  // If a number field is over the max
  if (validity.rangeOverflow) {
    throw new ValidationError(
      `Please select a value that is no more than ${field.getAttribute('max')}.`,
      field
    );
  }

  // If a number field is below the min
  if (validity.rangeUnderflow) {
    throw new ValidationError(
      `Please select a value that is no less than ${field.getAttribute('min')}.`,
      field
    );
  }

  // If pattern doesn't match
  if (validity.patternMismatch) {
    // If pattern info is included, return custom error
    if (field.hasAttribute('title')) {
      throw new ValidationError(field.getAttribute('title'), field);
    }

    // Otherwise, generic error
    throw new ValidationError('Please match the requested format.', field);
  }

  // if field password value doesn't match
  if (passwordMatch === false) {
    throw new ValidationError('Passwords do not match.', field);
  }

  // If all else fails, return a generic catchall error
  throw new ValidationError('The value you entered for this field is invalid.', field);
}

// Show an error message
export function showError(field, error) {
  let errored = field;

  // Add error class to field
  errored.parentNode.classList.add('in-error');

  // If the field is a radio button and part of a group, error all and get the last item in the group
  if (errored.type === 'radio' && errored.name) {
    var group = document.getElementsByName(errored.name);
    if (group.length > 0) {
      for (var i = 0; i < group.length; i++) {
        // Only check fields in current form
        if (group[i].form !== errored.form) { continue; }
        group[i].classList.add('error');
      }
      errored = group[group.length - 1];
    }
  }

  // Get field id or name
  var id = errored.id || errored.name;
  if (!id) { return; }

  // Check if error message field already exists
  // If not, create one
  var message = errored.form.querySelector('.error-message#error-for-' + id);
  if (!message) {
    message = document.createElement('div');
    message.className = 'error-message';
    message.id = 'error-for-' + id;

    // If the field is a radio button or checkbox, insert error after the label
    var label;
    if (errored.type === 'radio' || errored.type === 'checkbox') {
      label = errored.form.querySelector('label[for="' + id + '"]') || errored.parentNode;
      if (label) {
        label.parentNode.insertBefore(message, label.nextSibling);
      }
    }

    // Otherwise, insert it after the field
    if (!label) {
      errored.parentNode.insertBefore(message, errored.nextSibling);
    }
  }

  // Add ARIA role to the field
  errored.setAttribute('aria-describedby', 'error-for-' + id);

  // Update error message
  message.innerHTML = error;

  // Show error message
  message.style.display = 'block';
  message.style.visibility = 'visible';
}

// Remove the error message
export function removeError(field) {
  let cleared = field;

  // Remove error class to field
  cleared.parentNode.classList.remove('in-error');

  // Remove ARIA role from the field
  cleared.removeAttribute('aria-describedby');

  // If the field is a radio button and part of a group, remove error from all and get
  // the last item in the group
  if (cleared.type === 'radio' && cleared.name) {
    var group = document.getElementsByName(cleared.name);
    if (group.length > 0) {
      for (var i = 0; i < group.length; i++) {
        // Only check fields in current form
        if (group[i].form !== cleared.form) { continue; }
        group[i].classList.remove('error');
      }
      cleared = group[group.length - 1];
    }
  }

  // Get field id or name
  var id = cleared.id || cleared.name;
  if (!id) { return; }

  window.console.log(id);

  // Check if an error message is in the DOM
  var message = cleared.form.querySelector('.error-message#error-for-' + id + '');
  if (!message) { return; }

  // If so, hide it
  message.innerHTML = '';
  message.style.display = 'none';
  message.style.visibility = 'hidden';
}
