'use strict';

export default function () {
  function getScrollBarWidth() {
    let inner = document.createElement('p');
    inner.style.width = "100%";
    inner.style.height = "200px";
    let outer = document.createElement('div');
    outer.style.position = "absolute";
    outer.style.top = "0px";
    outer.style.left = "0px";
    outer.style.visibility = "hidden";
    outer.style.width = "200px";
    outer.style.height = "150px";
    outer.style.overflow = "hidden";
    outer.appendChild(inner);
    document.body.appendChild(outer);
    let w1 = inner.offsetWidth;
    outer.style.overflow = 'scroll';
    let w2 = inner.offsetWidth;
    if (w1 === w2) { w2 = outer.clientWidth }
    document.body.removeChild(outer);
    return ($(document).height() > $(window).height()) ? w1 - w2 : 0;
  }

  function toggleBackdrop(asideName) {
    console.log('run toggleBackdrop');
    let body = $('body'),
      backdrop = $('.modal-backdrop'),
      scrollBarwidth = getScrollBarWidth(),
      bodyPadRight = parseInt(body.css('padding-right'), 10);
    if ($(asideName).hasClass('open') && !backdrop.length) {
      body.append('<div class="modal-backdrop" data-dismiss="' + asideName + '"></div>');
      $('.modal-backdrop').addClass('fade show');
      body.addClass('modal-open');
      body.css('paddingRight', (scrollBarwidth + 'px'));
    }
    else {
      backdrop.remove();
      body.removeClass('modal-open');
      body.css('paddingRight', bodyPadRight - scrollBarwidth + 'px');
    }
  }

  $('body').on('click', '.close-aside, .modal-backdrop', function () {
    let aside = $(this).data('dismiss');
    $(aside).removeClass('open');
    $('.modal-backdrop').remove();
    toggleBackdrop(aside);
  });
  $('[data-toggle="aside-right"]').click(function () {
    let asideName = $(this).data('target'),
      aside = $(asideName);
    aside.appendTo('body');
    setTimeout(function () {
      aside.toggleClass('open');
      toggleBackdrop(asideName);
    }, 100);
  });
}
