'use strict';

export default function () {
  let revealWrappers = document.getElementsByClassName("js-step-reveal");

  for (let i = 0; i < revealWrappers.length; i++) {
    let el = revealWrappers[i],
      thisTrigger = el.getElementsByClassName("js-step-trigger"),
      thisTarget = Number(el.dataset.stepreveal) + 1,
      thisTargetElement = document.querySelectorAll('[data-stepreveal="' + thisTarget + '"]');

    if (thisTrigger[0] !== undefined) {
      thisTrigger[0].addEventListener("blur", function () {
        let isInError = thisTrigger[0].parentNode.className;
        if (isInError !== 'in-error') {
          thisTargetElement[0].classList.add('is-revealed');
        }
      }, true);
    }
  }
}
