'use strict';

export default function (elem) {

  function updateValue(options, display, direction, controls) {
    let val = parseInt(options.quantityInput.value, 10);
    if (direction === 'up' && (val + options.step <= options.max)) {
      val += options.step;
      if (val + options.step > options.max && controls) {
        controls.up.classList.add('disabled');
      }
      if (val - options.step >= options.min && controls) {
        controls.down.classList.remove('disabled');
      }
    }
    if (direction === 'down' && (val - options.step >= options.min)) {
      val -= options.step;
      if (val - options.step < options.min && controls) {
        controls.down.classList.add('disabled');
      }
      if (val + options.step <= options.max && controls) {
        controls.up.classList.remove('disabled');
      }
    }
    display.innerHTML = val;
    options.quantityInput.value = val;
  }

  function createControls(createQuantityControls, options) {
    let quantityControls = {};
    for (let i = 0; i < createQuantityControls.length; i++) {
      let controlType = createQuantityControls[i];
      let control = document.createElement('div');
      control.classList.add('quantity-' + controlType);
      quantityControls[controlType] = control;
    }
    quantityControls.down.addEventListener('click', function () {
      updateValue(options, quantityControls.display, 'down', quantityControls);
    });
    quantityControls.up.addEventListener('click', function () {
      updateValue(options, quantityControls.display, 'up', quantityControls);
    });
    return quantityControls;
  }

  function appendControls(quantity, quantityControls) {
    quantity.appendChild(quantityControls.controls);
    quantityControls.controls.appendChild(quantityControls.down);
    quantityControls.controls.appendChild(quantityControls.display);
    quantityControls.controls.appendChild(quantityControls.up);
  }

  let quantities = (elem) ? elem : document.getElementsByClassName('quantity-selector'),
    createQuantityControls = [
      'up',
      'down',
      'display',
      'controls'
    ];
  for (let i = 0; i < quantities.length; i++) {
    let quantity = quantities[i],
      quantityInput = quantity.querySelector('input'),
      options = {
        'quantityInput' : quantityInput,
        'min' : parseInt(quantityInput.getAttribute('min'), 10),
        'max' : parseInt(quantityInput.getAttribute('max'), 10),
        'step' : parseInt(quantityInput.getAttribute('step'), 10)
      };
    appendControls(quantity, createControls(createQuantityControls, options));
    quantity.querySelector('.quantity-display').innerHTML = quantityInput.value;
  }
}
