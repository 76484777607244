'use strict';

import flatpickr from "flatpickr";

const DEFAULT_DATEPICKER_CLASS = "datepicker";

const DEFAULT_FLATPICKR_OPTIONS = Object.freeze({
  altInput: true,
  allowInput: true,
  altFormat : "m/d/Y",
  dateFormat: "Y-m-d",
  minDate: "today",
  defaultDate: "today",
});

const FLATPICKR_LISTENERS = Object.freeze({
  onReady(selectedDates, dateStr, instance) {
    if (instance.input.value) { instance.altInput.parentNode.classList.add('is-filled')}
  },
  onValueUpdate(selectedDates, dateStr, instance) {
    if (instance.input.value) { instance.altInput.parentNode.classList.add('is-filled')}
  }
});

export default class DatePicker {
  constructor(datePicker, selector) {
    this.selector = selector;

    this.datePicker = datePicker;

    try {
      this.options = JSON.parse(this.datePicker.dataset.config);
    } catch (error) {
      this.options = {};
    }

    this.setPickerOptionsByType();

    this.flatpickrOptions = {
      ...DEFAULT_FLATPICKR_OPTIONS,
      ...this.options,
      ...FLATPICKR_LISTENERS
    };

    let _this = this;

    this.calendar = flatpickr(this.datePicker, this.flatpickrOptions);

    this.calendar._input.addEventListener('input', function (event) {
      let date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
      let checkDate = event.target.value;
      if ((date_regex.test(checkDate)))
      {
        _this.calendar.setDate(flatpickr.parseDate(event.target.value, "m/d/Y"));
      }
    }, true);

    this.calendar._input.addEventListener('blur', function (event) {
      let date_regex = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/ ;
      let checkDate = event.target.value;

      let today = new Date(),
        dd = today.getDate(),
        mm = today.getMonth() + 1, //January is 0!
        yyyy = today.getFullYear();
      (dd < 10) ? dd = '0' + dd : '';
      (mm < 10) ? mm = '0' + mm : '';
      today = mm + '/' + dd + '/' + yyyy;

      if ((!date_regex.test(checkDate)) && checkDate)
      {
        _this.calendar.setDate(flatpickr.parseDate(today, "m/d/Y"));
        event.target.value = today;
      }
    }, true);
  }

  static init(selector = DEFAULT_DATEPICKER_CLASS) {
    const datePickers = document.querySelectorAll(`.${selector}`);
    return [...datePickers].map(datePicker => new DatePicker(datePicker, selector));
  }

  setPickerOptionsByType() {
    const classList = this.datePicker.classList;

    if (classList.contains(`${this.selector}-inline`)) {
      this.options.inline = true;
    }

    if (classList.contains(`${this.selector}-range`)) {
      this.options.defaultDate = null;
      this.options.mode = "range";
    }

    if (classList.contains(`${this.selector}-time`)) {
      this.options.enableTime = true;
      this.options.altFormat = "F j, Y h:i K";
      this.options.dateFormat = "Y-m-d H:i";
      (this.datePicker.dataset.minIncrement) ? this.options.minuteIncrement = this.datePicker.dataset.minIncrement : '';
    }

    if (classList.contains(`${this.selector}-no-input`)) {
      this.options.allowInput = false;
    }
  }
}

function initDatePicker(elem) {
  let datepicker = elem[0];
  const classList = datepicker.classList;
  let customOptions = {};
  let defaultOptions = {
    altInput: true,
    allowInput: true,
    altFormat : "m/d/Y",
    dateFormat: "Y-m-d",
    minDate: "today",
    defaultDate: "today",
  };

  if (classList.contains(`datepicker-inline`)) {
    customOptions.inline = true;
  }

  if (classList.contains(`datepicker-range`)) {
    customOptions.defaultDate = null;
    customOptions.mode = "range";
  }

  if (classList.contains(`datepicker-time`)) {
    customOptions.enableTime = true;
    customOptions.altFormat = "F j, Y h:i K";
    customOptions.dateFormat = "Y-m-d H:i";
  }

  if (classList.contains(`datepicker-no-input`)) {
    customOptions.allowInput = false;
  }

  let datePickerOptions = {...customOptions, ...defaultOptions};

  window.DatePicker = flatpickr(elem[0], datePickerOptions);
}

export {initDatePicker};
