'use strict';

export default function (elem) {
  let ratings = (elem) ? elem : document.getElementsByClassName('star-ratings-interactive');
  for (let i = 0; i < ratings.length; i++) {
    let el = ratings[i],
      ratingUpper = 5,
      ratingList = document.createElement('div');
    ratingList.className = 'rating';
    el.appendChild(ratingList);
    let starList = el.getElementsByClassName('rating');
    for (let i2 = 0; i2 < ratingUpper; i2++) {
      starList[0].innerHTML += '<label class="star-label"><input type="radio" name="rating"/><svg class="icon icon-sm icon-empty"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-star"></use></svg><svg class="icon icon-sm icon-filled primary"><use xmlns:xlink="http://www.w3.org/1999/xlink" xlink:href="#icon-star-filled"></use></svg></label>';
    }
    let stars = el.getElementsByClassName('star-label');
    for (let i3 = 0; i3 < stars.length; i3++) {
      let star = stars[i3];
      star.addEventListener('click', function () {
        for (let i4 = 0; i4 < stars.length; i4++) {
          stars[i4].classList.remove('selected-star');
        }
        star.classList.add('selected-star');
        ratingList.classList.add('selected');
      })
    }
  }
}

