export default function (elem) {

  function buildThumbs(container, controlType) {
    let carouselName = container.id,
      carouselControls = container.querySelectorAll('.carousel-controls'),
      carouselItems = container.querySelectorAll('.carousel-item'),
      thumbsList = document.createElement('ol');
    thumbsList.classList.add(controlType);
    for (let i = 0; i < carouselItems.length; i++) {
      let item = document.createElement('li'),
        carouselItem = carouselItems[i];
      item.setAttribute('data-target', '#' + carouselName);
      item.setAttribute('data-slide-to', i);
      let preview = carouselItem.querySelector('img');
      (i === 0) ? item.classList.add('active') : '';
      (carouselItem.classList.contains('video')) ? item.classList.add('video') : '';
      if (controlType === 'carousel-thumbnails' && preview) {
        item.appendChild(preview.cloneNode(false));
      }
      thumbsList.appendChild(item);
    }
    carouselControls[0].appendChild(thumbsList);
  }

  function enableControls(el) {
    let thumbnails = el.querySelectorAll('.carousel-thumbnails');
    thumbnails = thumbnails[0];
    let prev = el.querySelectorAll('.thumbnail-prev'),
      next = el.querySelectorAll('.thumbnail-next'),
      scrollSpeed = 800;
    prev[0].addEventListener('click', function () {
      let scrollLength = thumbnails.offsetWidth,
        scrollPosition = thumbnails.scrollLeft,
        scroll = scrollPosition - scrollLength;
      $(thumbnails).animate({scrollLeft: scroll}, scrollSpeed)
    });
    next[0].addEventListener('click', function () {
      let scrollLength = thumbnails.offsetWidth,
        scrollPosition = thumbnails.scrollLeft,
        scroll = scrollLength + scrollPosition;
      $(thumbnails).animate({scrollLeft: scroll}, scrollSpeed);
    });

    //change active thumbnail when carousel slides
    $(el).bind('slide.bs.carousel', function (e) {
      $(this).find('.carousel-thumbnails li').removeClass('active');
      let item = $(this).find('.carousel-thumbnails li[data-slide-to="' + e.to + '"]')[0];
      $(item).addClass('active');
    });
  }

  let carousel = (elem) ? elem : document.getElementsByClassName('carousel');
  for (let i = 0; i < carousel.length; i++) {
    let el = carousel[i],
      controlType = (el.classList.contains('thumbnails')) ? 'carousel-thumbnails' : 'carousel-indicators';

    //create thumbnails and click functionality
    buildThumbs(el, controlType);
    if (controlType === 'carousel-thumbnails') {
      enableControls(el);
    }
  }
}
