'use strict';

import DatePicker from './forms/datepicker';
import Validate from './forms/validate/validate.js';
import Textarea from './forms/textarea.js';
import selectAll from './forms/select-all';
import ready from './utils/ready.js';

import "arrive/minified/arrive.min";
import "bootstrap-material-design/js/baseInput";
import "bootstrap-material-design/js/util";
import svg4everybody from 'svg4everybody';
import Animate from './animate';
import buildSelect from './dropdowns';
import powerHoverBoard from './hoverboard';
import powerModals from './modals';
import powerMenu from './nav';
import powerRatingsStatic from './ratings-static';
import powerRatingsInteractive from './ratings-interactive';
import powerEditInline from './inline-edit';
import powerEditBulk from './bulk-edit';
import multiSelect from './multiSelect';
import powerCarousel from './carousel';
import dragNDrop from './dropzone';
import powerCharts from './charts';
import powerTools from './tool';
import stepReveal from './step-reveal';
import conditionallyEnableButtons from './conditional-enable-button';
import powerQuantity from './forms/quantity';
import componentListeners from './component-listeners';
import settings from './settings.json';

import components from './components';


/* globals Popper */
Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false

/**
 * ------------------------------------------------------------------------
 * Constants
 * ------------------------------------------------------------------------
 */
//const NAME = "bootstrapMaterialDesign";
//const DATA_KEY = `bmd.${NAME}`;
const NAME = "rentjoyUI";
const DATA_KEY = `rju.${NAME}`;
const JQUERY_NAME = NAME; // retain this full name since it is long enough not to conflict
const JQUERY_NO_CONFLICT = jQuery.fn[JQUERY_NAME];

/**
 * Global configuration:
 *  The global configuration hash will be mixed in to each components' config.
 *    e.g. calling jQuery.rentjoyUI({global: { validate: true } }) would pass `validate:true`
 *    to every component
 *
 * Component configuration:
 *  - selector: may be a string or an array.  Any array will be joined with a comma to generate the selector
 *  - disable any component by defining it as false with an override.
 *    e.g. jQuery.rentjoyUI({ autofill: false })
 *
 *  @see each individual component for more configuration settings.
 */
const Default = {
  global: {
    validate: false,
    label: {
      className: "bmd-label-static" // default style of label to be used if not specified in the html markup
    }
  },
  autofill: {
    selector: "body"
  },
  checkbox: {
    selector: ".checkbox > label > input[type=checkbox]"
  },
  checkboxInline: {
    selector: "label.checkbox-inline > input[type=checkbox]"
  },
  collapseInline: {
    selector: '.bmd-collapse-inline [data-toggle="collapse"]'
  },
  drawer: {
    selector: ".bmd-layout-drawer"
  },
  file: {
    selector: "input[type=file]"
  },
  radio: {
    selector: ".radio > label > input[type=radio]"
  },
  radioInline: {
    selector: "label.radio-inline > input[type=radio]"
  },
  ripples: {
    //selector: ['.btn:not(.btn-link):not(.ripple-none)'] // testing only
    selector: [
      ".btn:not(.btn-link):not(.ripple-none)",
      ".card-image:not(.ripple-none)",
      ".navbar a:not(.ripple-none)",
      ".dropdown-menu a:not(.ripple-none)",
      ".nav-tabs a:not(.ripple-none)",
      ".pagination li:not(.active):not(.disabled) a:not(.ripple-none)",
      ".ripple" // generic marker class to add ripple to elements
    ]
  },
  select: {
    selector: ["select"]
  },
  switch: {
    selector: ".switch > label > input[type=checkbox]"
  },
  text: {
    // omit inputs we have specialized components to handle - we need to match text, email, etc.  The easiest way to do this appears to be just omit the ones we don't want to match and let the rest fall through to this.
    selector: [
      `input:not([type=hidden]):not([type=checkbox]):not([type=radio]):not([type=file]):not([type=button]):not([type=submit]):not([type=reset]):not([type=search]):not(.numInput)`
    ]
  },
  textarea: {
    selector: ["textarea"]
  },
  arrive: true,

  // create an ordered component list for instantiation
  instantiation: [
    "ripples",
    "checkbox",
    "checkboxInline",
    "collapseInline",
    "drawer",

    // 'file',
    "radio",
    "radioInline",
    "switch",
    "text",
    "textarea",
    "select",
    "autofill"
  ]
};

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */
export default class RentJoyUI {
  constructor($element, config) {
    this.$element = $element;
    this.config = jQuery.extend(true, {}, Default, config);
    let $document = $(document);

    for (let component of this.config.instantiation) {
      // the component's config fragment is passed in directly, allowing users to override
      let componentConfig = this.config[component];

      // check to make sure component config is enabled (not `false`)
      if (componentConfig) {
        // assemble the selector as it may be an array
        let selector = this._resolveSelector(componentConfig);

        // mix in global options
        componentConfig = jQuery.extend(
          true,
          {},
          this.config.global,
          componentConfig
        );

        // create the jquery fn name e.g. 'bmdText' for 'text'
        let componentName = `${component.charAt(0).toUpperCase() +
        component.slice(1)}`;
        let jqueryFn = `bmd${componentName}`;

        try {
          // safely instantiate component on selector elements with config, report errors and move on.
          // console.debug(`instantiating: $('${selector}')[${jqueryFn}](${componentConfig})`) // eslint-disable-line no-console
          $(selector)[jqueryFn](componentConfig);

          // add to arrive if present and enabled
          if (document.arrive && this.config.arrive) {
            $document.arrive(selector, function () {
              // eslint-disable-line no-loop-func
              $(this)[jqueryFn](componentConfig);
            });
          }
        } catch (e) {
          let message = `Failed to instantiate component: $('${selector}')[${jqueryFn}](${componentConfig})`;
          console.error(message, e, `\nSelected elements: `, $(selector)); // eslint-disable-line no-console
          throw e;
        }
      }
    }
  }

  dispose() {
    this.$element.data(DATA_KEY, null);
    this.$element = null;
    this.config = null;
  }

  // ------------------------------------------------------------------------
  // private

  _resolveSelector(componentConfig) {
    let selector = componentConfig.selector;
    if (Array.isArray(selector)) {
      selector = selector.join(", ");
    }

    return selector;
  }

  // ------------------------------------------------------------------------
  // static
  static _jQueryInterface(config) {
    return this.each(function () {
      let $element = $(this);
      let data = $element.data(DATA_KEY);

      if (!data) {
        data = new RentJoyUI($element, config);
        $element.data(DATA_KEY, data);
      }
    });
  }
}

/**
 * ------------------------------------------------------------------------
 * jQuery
 * ------------------------------------------------------------------------
 */
jQuery.fn[JQUERY_NAME] = RentJoyUI._jQueryInterface;
jQuery.fn[JQUERY_NAME].Constructor = RentJoyUI;
jQuery.fn[JQUERY_NAME].noConflict = () => {
  jQuery.fn[JQUERY_NAME] = JQUERY_NO_CONFLICT;
  return RentJoyUI._jQueryInterface;
};

ready(() => {

  jQuery('body').rentjoyUI();

  jQuery(function () {
    jQuery('[data-toggle="tooltip"]').tooltip();
    jQuery('.popover').popover();
    jQuery('.popover-dismiss').popover({
      trigger: 'focus'
    });
  });

  Animate();
  jQuery(window).scroll(function () {
    (jQuery('.animate').length) ? Animate() : null;
  });

  let svgPath = settings.host + settings.basePath + 'images/svg/icons.svg';

  let ajax = new XMLHttpRequest();
  ajax.open("GET", svgPath, true);
  ajax.onload = function () {
    let div = document.createElement("div");
    div.innerHTML = ajax.responseText;
    document.body.insertBefore(div, document.body.childNodes[0]);
    svg4everybody();
  };
  ajax.onerror = function () {
    // do nothing...
  };
  ajax.send();

  powerHoverBoard();
  powerMenu();
  powerModals();
  buildSelect();
  multiSelect();

  DatePicker.init();
  Textarea.init();
  Validate.init();
  selectAll();
  powerRatingsStatic();
  powerRatingsInteractive();
  powerEditInline();
  powerEditBulk();
  powerCarousel();
  dragNDrop();
  powerCharts();
  powerTools();
  stepReveal();
  conditionallyEnableButtons();
  powerQuantity();
  componentListeners();

  components();
});
