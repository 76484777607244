export default function (elem) {
  // let bulkEditForms = document.getElementsByClassName("js-bulk-edit-form-wrapper");

  var bulkEditForms = (elem) ? elem : document.getElementsByClassName('js-bulk-edit-form-wrapper');

  if (bulkEditForms) {
    var grabInitialValuesOfInputs = function (controls) {
      for (let i = 0; i < controls.length; i++) {
        let control = controls[i];
        var initialValue = control.value;
        control.setAttribute('data-initial-value', initialValue);
      }
    };

    var replaceValuesWithInitialValuesOfInputs = function (controls) {

      for (let i = 0; i < controls.length; i++) {
        let control = controls[i];
        console.log('control initial value was: ', control.dataset.initialValue);
        console.log('control initial value length: ', control.dataset.initialValue.length);
        var initialValue = control.dataset.initialValue;
        control.value = initialValue;
        if (control.dataset.initialValue.length > 0) {
          console.log("this control is: ", control);
          control.closest('.bmd-form-group').classList.add('is-filled');
        }
      }
    };

    var checkEditMode = function (controls, thisForm) {
      if (thisForm.thisEditModeIsOn) {
        replaceValuesWithInitialValuesOfInputs(controls, thisForm);
        thisForm.removeItemsPreppedForDeleting();
        thisForm.gatherErrorStates();
        thisForm.thisEditModeIsOn = !(thisForm.thisEditModeIsOn);
      } else {
        grabInitialValuesOfInputs(controls, thisForm);
        thisForm.thisEditModeIsOn = !(thisForm.thisEditModeIsOn);
      }
    };

    Array.prototype.forEach.call(bulkEditForms, function (bulkEditForm) {
      console.log(bulkEditForm.tagName);
      var thisBulkEditForm = bulkEditForm;
      thisBulkEditForm.thisToggleEdit = (thisBulkEditForm.getElementsByClassName("toggle-link"))[0];
      thisBulkEditForm.thisToggleEditID = thisBulkEditForm.id;
      thisBulkEditForm.thisDeleteGroupsArray = [];
      thisBulkEditForm.thisBulkEditFormID = thisBulkEditForm.id;
      thisBulkEditForm.thisEditModeIsOn = false;

      thisBulkEditForm.removeItemsPreppedForDeleting = function () {
        var itemsForDeletion = thisBulkEditForm.thisDeleteGroupsArray;
        thisBulkEditForm.thisDeleteGroupsArray = [];
        for (let i = 0; i < itemsForDeletion.length; i++) {
          let item = itemsForDeletion[i];
          item.classList.remove('is-deleting');
        }
      };

      thisBulkEditForm.removeErrorMsg = function (thisErrorItem) {
        return new Promise(function (resolve) {
          var thisInput = thisErrorItem.getElementsByTagName('input')[0];
          var thisItemID = thisInput.id;
          $("#error-for-" + thisItemID).html(" ");
          resolve(thisErrorItem);
        });

      };

      thisBulkEditForm.keyUpErrorInput = function (thisErrorItem) {
        return new Promise(function (resolve) {
          var thisInput = thisErrorItem.getElementsByTagName('input')[0];
          var thisItemID = thisInput.id;
          $("#" + thisItemID).keyup();
          resolve(thisErrorItem);
        });
      };

      thisBulkEditForm.removeErrorState = function (thisItem) {
        return new Promise(function () {
          console.log("Need to remove this element: ", thisItem);
          console.log("Here is the classList: ", thisItem.classList);
          thisItem.classList.remove('in-error');
        });
      };

      thisBulkEditForm.gatherErrorStates = function () {
        var itemsWithErrors = thisBulkEditForm.getElementsByClassName('in-error');

        for (var i = 0; i < itemsWithErrors.length; i++) {
          var thisErrorItem = itemsWithErrors[i];

          //Need all items to run before calling removeErrorState function.
          thisBulkEditForm.keyUpErrorInput(thisErrorItem, thisErrorItem).then(function (result) {
            return thisBulkEditForm.removeErrorMsg(result);
          }).then(function (result) {
            return thisBulkEditForm.removeErrorState(result);
          });
        }
      };

      thisBulkEditForm.thisToggleEdit.addEventListener('click', function () {
        (thisBulkEditForm.classList.contains('editing')) ? thisBulkEditForm.classList.remove('editing') : thisBulkEditForm.classList.add('editing');
        let el = thisBulkEditForm,
          controls = el.getElementsByClassName("form-control");
        for (let i2 = 0; i2 < controls.length; i2++) {
          let control = controls[i2];
          (control.hasAttribute('disabled')) ? control.removeAttribute('disabled') : control.setAttribute('disabled', 'true');
        }
        checkEditMode(controls, thisBulkEditForm);
      });

      thisBulkEditForm.getDeleteGroups = function () {
        var deleteGroups = thisBulkEditForm.getElementsByClassName("form-delete-group");
        for (let i = 0; i < deleteGroups.length; i++) {
          let deleteGroup = deleteGroups[i],
            deleteTrigger = deleteGroup.getElementsByClassName("form-delete-group-trigger");
          deleteTrigger[0].addEventListener('click', function () {
            deleteGroup.classList.add('is-deleting');
            thisBulkEditForm.thisDeleteGroupsArray.push(deleteGroup);
            console.log('Here is what is in thisDeleteGroupsArray: ', thisBulkEditForm.thisDeleteGroupsArray);
            var domWithInputsToDelete = thisBulkEditForm.thisDeleteGroupsArray[0];
            var inputsDeleted = domWithInputsToDelete.getElementsByTagName('input');
            console.log("domWithInputsToDelete is: ", domWithInputsToDelete);

            for (let i2 = 0; i2 < inputsDeleted.length; i2++) {
              let inputDeleted = inputsDeleted[i2];
              inputDeleted.value = '';
            }
            console.log("inputsDeleted is: ", inputsDeleted);
          });
        }
      };

      thisBulkEditForm.getDeleteGroups();
    });
  }
}
