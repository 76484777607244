'use strict';

export default function () {
  function activateHoverBoard(navType, nav) {
    nav.append('<div class="hover-board"></div>');
    let hoverBoard = $('.hover-board'),
      menuItem = nav.find('.nav-link'),
      activeItem = nav.find('.nav-link.active');
    if (navType === 'vertical') {
      hoverBoard.height((activeItem / 2));
      let activeItemHeight = activeItem.height(),
        hoverBoardInitPos = (activeItem.offset().top - nav.offset().top + nav.scrollTop() + (activeItemHeight / 4));
      hoverBoard.css('top', hoverBoardInitPos + 'px');
      menuItem.hover(
        function () {
          let hoverBoardPos = ($(this).offset().top - nav.offset().top + nav.scrollTop() + (activeItemHeight / 4));
          hoverBoard.css('top', hoverBoardPos + 'px');
        },
        function () {
          let hoverPosActive = ($('.left-navbar .nav-link.active').offset().top - nav.offset().top + nav.scrollTop() + (activeItemHeight / 4));
          hoverBoard.css('top', hoverPosActive + 'px');
        }
      );
    }
    else if (navType === 'tabs') {
      let tabHoverBoard = nav.find(hoverBoard);
      tabHoverBoard.width(activeItem.width());
      let hoverBoardInitPos = (activeItem.offset().left - activeItem.closest('ul.nav-tabs').offset().left + parseInt(activeItem.css('padding-left'), 10));
      tabHoverBoard.css('left', hoverBoardInitPos + 'px');
      menuItem.hover(
        function () {
          let hoverBoardPos = ($(this).offset().left - $(this).closest('ul.nav-tabs').offset().left + parseInt($(this).css('padding-left'), 10));
          tabHoverBoard.css('left', hoverBoardPos + 'px');
          tabHoverBoard.width($(this).width());
        },
        function () {
          let newActiveItem = nav.find('.nav-link.active'),
            hoverPosActive = (newActiveItem.offset().left) - $(this).closest('ul.nav-tabs').offset().left + parseInt($(this).css('padding-left'), 10);
          tabHoverBoard.css('left', hoverPosActive + 'px');
          tabHoverBoard.width(newActiveItem.width());
        }
      );
    }
  }

  if ($('.nav-vertical').length) {
    $('.nav-vertical').each(function () {
      if ($(this).find('.hover-board').length < 1) {
        activateHoverBoard('vertical', $(this));
      }
    });
  }

  if ($('.nav-tabs').length) {
    $('.nav-tabs').each(function () {
      if ($(this).find('.hover-board').length < 1) {
        activateHoverBoard('tabs', $(this))
      }
    });
  }
}
